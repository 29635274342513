import Api from 'utils/api'
import i18n from 'utils/i18n'
import {produce} from 'immer'


export const bookingDetailSlice = ((set, get) => ({

  defaultBookingDetailValues: {
    collection_bags: 0,
    collection_child_carriers: 0,
    collection_odd_sized_luggages: 0,
    collection_address: '',
    collection_room_number: '',
    collection_at: null,
    delivery_bags: 0,
    delivery_child_carriers: 0,
    delivery_odd_sized_luggages: 0,
    delivery_address: '',
    delivery_room_number: '',
    delivery_at: null
  },

  bookingDetail: null,
  bookingDetails: [],

  actions: {
    estimatePrice: async (params, callback) => {
      get().actions.startLoading();
      const api = new Api();

      let data = {};
      data.booking_detail = params.bookingDetail;

      api.estimatePrice(data)
        .then((response) => {
          callback(response.data.eprice);
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
      });
    },

    saveBookingDetail: async (params, callback) => {
      get().actions.startLoading();
      const api = new Api();

      let data = {};
      data.flight_record = params.flightRecord;
      data.booking_detail = params.bookingDetail;
      data.booking_code = params.bookingCode;
      data.type_for = params.typeFor;

      api.saveBookingDetail(data)
        .then((response) => {
          const flightRecords_ = get().flightRecords.map(flightRecord => 
          {
            if (data.flight_record.id !== flightRecord.id) return flightRecord;
            let flightRecord_ = {...flightRecord};
            flightRecord_.booking_detail= {...response.data.booking_detail};
            return flightRecord_;
          });
          set(produce((state) => { state.flightRecords = flightRecords_}));

          get().actions.fetchBooking({bookingCode: response.data.booking_detail.booking_code});
          try { callback(); } catch (e) {}
          get().actions.stopLoading();
          get().actions.setSnackMessage({show: true, type: "success", text: i18n.t("Data saved successfully")})
        })
      .catch((err) => {
        get().actions.stopLoading();
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },


    setItemValue: (fr, value, item, itemType) => {

      if (itemType === "Bag"){
        let max_bags = parseInt(get().config.max_bags);
        let min_bags = parseInt(get().config.min_bags);
        if (value < min_bags) value = min_bags;
        if (value > max_bags) value = max_bags;
        if (value < 0) value = 0;
      }

      if (fr.id === -1) {
        set(produce((state) => { 
            state.bookingDetail[item] = value;
        }));
        return;
      }

      if (itemType === "Date"){
        value = new Date(value);
      }

      let flightRecords = [...get().flightRecords];
      const flightRecords_ = flightRecords.map(flightRecord => 
      {
        if (fr.id !== flightRecord.id) return flightRecord;
        let flightRecord_ = {...flightRecord};
        let booking_detail = {...flightRecord_.booking_detail};
        booking_detail[item] = value;
        flightRecord_.booking_detail= {...booking_detail};
        return flightRecord_;
      });

      set(produce((state) => { state.flightRecords = flightRecords_}));
    },

    resetBookingDetail: () => {
      set(produce((state) => { 
          state.bookingDetail = get().defaultBookingDetailValues;
      }));
    }

  }

}))