import React from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import BagType from "./BagType/BagType"
import { useTranslation } from 'react-i18next';

import {
  BagsType, FlightStepType
} from "components/shared/enums/global-enums.tsx";

import bags from "assets/images/bags.png";
import child_carriers from "assets/images/child_carriers.png";
import odd_sized_luggages from "assets/images/odd_sized_luggages.png";

import bags_bis from "assets/images/bags_bis.png";
import child_carriers_bis from "assets/images/child_carriers_bis.png";
import odd_sized_luggages_bis from "assets/images/odd_sized_luggages_bis.png";


import './Bags.scss'

export default function Bags({typeFor, flightRecord}){

  const {t} = useTranslation();

  const imgs = {
    [FlightStepType.COLLECTION]: {
      bags: bags_bis,
      child_carriers: child_carriers_bis,
      odd_sized_luggages: odd_sized_luggages_bis
    },
    [FlightStepType.DELIVERY]: {
      bags: bags,
      child_carriers: child_carriers,
      odd_sized_luggages: odd_sized_luggages
    }
  }

  return (
    <>

      <Box className={`bags-container col-modifier ${typeFor}`} >
        <Grid>
          <Grid className="">
            <span className="accent2-color para1 font-1-medium">{t("What")}</span>
            <span className="cmn-color para1 font-1-medium">{t(" would you like to " + (typeFor === FlightStepType.COLLECTION ? "check-in?" : "have delivered?" ))}</span>
          </Grid>
          <div className="v-space-2"></div>
          <Grid>
            <span className={`para3 font-1-light-bt cmn-color`}>{t("You can update this later if your plans change")}</span>
          </Grid>
        </Grid>
        <div className="v-space-5"></div>
        <Grid container spacing={2}>
          <Grid item xs={12} className="d-f jc-c">
            <BagType flightRecord={flightRecord} typeFor={typeFor} text={t("Bags")} subText={t("Standard bags < 32kg")} itemName={BagsType.BAGS} img={imgs[typeFor][BagsType.BAGS]}/>
          </Grid>
          <Grid  item xs={12}  className="d-f jc-c">
            <BagType flightRecord={flightRecord} typeFor={typeFor} text={t("Child carriers")} subText={t("Prams, strollers, car seats, etc.")} itemName={BagsType.CHILD_CARRIERS} img={imgs[typeFor][BagsType.CHILD_CARRIERS]}/>
          </Grid>
          <Grid  item xs={12}  className="d-f jc-c">
            <BagType flightRecord={flightRecord} typeFor={typeFor} text={t("Odd-Sized")} subText={t("Instruments, surfboards, bikes, etc.")} itemName={BagsType.ODD_SIZED_LAGGAGES} img={imgs[typeFor][BagsType.ODD_SIZED_LAGGAGES]}/>
          </Grid>
        </Grid>
      </Box>

    </>
    )
}