import React, { useRef, useEffect }  from "react"
import logo from "assets/images/biglogo.png"

import { useTranslation } from 'react-i18next';
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import useStore from 'store/store'
import './Idle.scss'


export default function Idle(){
  const {t} = useTranslation();
  const storeActions = useStore((state)=> state.actions);
  const mainRef = useRef(null);
  const [redirect,] = useSelfCheckinNafigation();

  useEffect(() => {
    mainRef.current.focus();
  }, [mainRef]);

  useEffect(()=>{
    storeActions.setSelfCheckinChoicesInit();
  }, [storeActions]);


  return (
    <div ref={mainRef} tabIndex="-1" className="sc-cover-bloc" onKeyDown={()=>redirect(1)} onClick={()=>redirect(1)}>
      <div className="floating-logo">
        <img src={logo} alt=""/>
        <div className="font-1-heavy h3 c-txt uppercase white-color">{t("TOUCH ANYWHERE TO BEGIN")}</div>
      </div>
    </div>
    )
}