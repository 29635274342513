import React, { useEffect, useState }  from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CTA from "components/Landing/CTA/CTA"
import Partner from "components/Landing/Partner/Partner"
import Traveller from "components/Landing/Traveller/Traveller"

import logo from "assets/images/logo.png"

import { useTranslation } from 'react-i18next';

import './Landing.scss'

export default function Landing(){
  const {t} = useTranslation();

  const [slideUp, setSlideUp] = useState(false);

  const [step, setStep] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setSlideUp(true);
    }, 2000);
  }, []);

    

  return (
    <div>
      <div className="landing-cover-bloc">
        <div className="v-space-10"></div>
        <div className="v-space-10"></div>
          <Container>
            <Grid item xs={12} md={12} lg={7} className="white-color big-cp font-1-heavy"> 
              
               {t("SAY GOODBYE TO EMOTIONAL BAGGAGE")}
            
            </Grid>
          </Container>
          
          { step === 0 && <CTA slideUp={slideUp} setStep={setStep}/> }

          { step === 1 && <Partner setStep={setStep} /> }

          { step === 2 && <Traveller /> }

          {
            !slideUp &&
            <div className="floating-logo">
              <img className="img" src={logo} alt=""/>
              <div className="font-1-medium para1 uppercase white-color">PORTAL</div>
            </div>
          }
      </div>
    </div>
    )
}