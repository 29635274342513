import React from "react"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SelfCheckinStepEnum } from "components/shared/enums/global-enums.tsx";
import Initial from "./Initial/Initial"
import BagsCount from "./BagsCount/BagsCount"
import PrintChoices from "./PrintChoices/PrintChoices"
import './Actions.scss'

export default function Actions({sCC, index, gTypeFor}){

  return (
    <>
      <Box className="actions-container">
        <Grid  container className={`d-f fd-c`}>
          { ((gTypeFor !== SelfCheckinStepEnum.ITEMS_TO_PRINT && ((sCC.typeFor === SelfCheckinStepEnum.PASSENGER && <Initial sCC={sCC} index={index} />) || (sCC.typeFor === SelfCheckinStepEnum.SELECT_BAGS && <BagsCount sCC={sCC} index={index} />))) ||
           (gTypeFor === SelfCheckinStepEnum.ITEMS_TO_PRINT && <PrintChoices sCC={sCC} index={index}/>)) }
        </Grid>
      </Box>
    </>
    )
}
