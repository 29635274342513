import React from "react"
import {Link} from "react-router-dom";

import Header from "components/shared/Header/Header"

import {
  ButtonTypeEnum, ButtonSizeEnum
} from "components/shared/enums/global-enums.tsx";

import { useTranslation } from 'react-i18next';

import AppButton from "components/shared/AppButton/AppButton"
import {AppRoutes} from "utils/routes";
import './Page.scss'

export default function Page(){
  const {t} = useTranslation();

  return (
    <div>
      <Header/>
      

      <div className="d-f jc-c ai-c h-100 fd-c "> 
        <div className="v-space-10"></div>
        <div className="v-space-10"></div>
        <div className="h6">
          { t("This page is still under construction") }
        </div>
        <div className="v-space-6"></div>
        <Link to={AppRoutes.selfCheckinPath}>
          <AppButton 
            buttonLabel={ t("Try Self Check-in") }
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.MEDIUM}
          />
        </Link>

        <div className="v-space-5"></div>        

        <Link to={AppRoutes.scaleTestPath}>
          <AppButton 
            buttonLabel={ t("Try Scale function") }
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.MEDIUM}
          />
        </Link>
      </div>
      <div className="v-space-10"></div>
      <div className="v-space-10"></div>

    </div>
    )
}