import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";
import './Loading.scss'


export default function Loading({theme}){

  const {t} = useTranslation();

  return (
    <div className={`loading-container ${theme}`}>
      
      <CircularProgress sx={{color: variables["primary1-color"]}} size={45} thickness={5}/>
      <div className="v-space-5" ></div>
      <div className="h6 font-1-heavy primary1-color">
        {t("Loading")}
      </div>
    </div>
  )
}