import React from "react"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Links from 'components/Landing/Links/Links';
import { useTranslation } from 'react-i18next';
import AccessForm from 'components/Landing/AccessForm/AccessForm';
import * as Yup from 'yup';
import {
  AudienceTypes
} from "components/shared/enums/global-enums.tsx";

import useStore from "store/store";
import orderedPeople  from 'assets/images/orderedPeople.png'
import './Traveller.scss'


const emSchema = Yup.object().shape({
  email: Yup.string()
   .required('Required').email()
});

const emInitValues = { 
  email: ''
};

const emInputs = [
  {
    name: "email",
    placeholder: "Enter your email address"
  }
]


export default function Traveller(){
  
  const {t} = useTranslation();

  const storeActions = useStore((state)=> state.actions);


  return (
    <div className="traveller-block">
      <div className="v-space-10"></div>
      <div className="v-space-5"></div>
      <Container>

        <Grid container className="white-color" > 

          <Grid container item xs spacing={2}>
            <Grid container item xs={12}>
              <div className="h5 font-1-heavy ">        
                {t("NO MATTER WHO YOU ARE, WE WILL MAKE YOUR TRIPS BETTER.")}
              </div> 

              <div className="v-space-8"></div>
              <div className="para1 font-1-heavy ">        
                {t("SIGN UP FOR OUR MAILING LIST OR READ THE FAQs BELOW.")}
              </div> 
              
              <div className="v-space-8"></div>
            </Grid>

            <AccessForm type={AudienceTypes.TRAVELLER} validationSchema={emSchema} initValues={emInitValues} inputs={emInputs} action={storeActions.createUser} title={t("LET US KNOW HOW TO REACH YOU")} btnTitle={t("SAY HI")}  />


            <Grid container className="d-f" item xs={12} >

              <Links linksFor={AudienceTypes.TRAVELLER}/>
            </Grid>
            
          </Grid>

          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'none',  md: 'flex' } }} className=" jc-c ai-b">
            <img src={orderedPeople} alt="" width="380" />
          </Grid>

        </Grid>

        <div className="v-space-5"></div>
      
      </Container>
      <div className="v-space-10"></div>
    </div>
    )
}