import React, {Fragment, useEffect}  from "react"
import {useSearchParams, Link, generatePath} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"
import Services from "components/Services/Services"
import Flight from "components/Flight/Flight"
import ErrorIcon from '@mui/icons-material/Error';
import Header from "components/shared/Header/Header"
import Footer from "components/shared/Footer/Footer"
import {AppRoutes} from "utils/routes";
import variables from "utils/_variables.module.scss";
import useStore from 'store/store'
import './FlightsList.scss'

export default function FlightsList(){
  let [searchParams, ] = useSearchParams();
  const flightRecords = useStore((state)=> state.flightRecords);

  const storeActions = useStore((state)=> state.actions);
  const booking = useStore((state)=> state.booking);

  useEffect(()=>{
    storeActions.setFlightRecords([]);
    storeActions.toggleFlightStepPanel('');
  }, [storeActions]);

  let bookingCode = flightRecords?.find(fr => {
    return (fr?.booking_detail?.booking_code) 
  })?.booking_detail?.booking_code;

  let lockedFlightFound = flightRecords?.find(fr => {
    return (fr?.locked===true && !fr?.booking_detail) 
  });

  useEffect(()=>{
    if (bookingCode) storeActions.fetchBooking({bookingCode: bookingCode});
  }, [bookingCode, storeActions]);

  useEffect(()=>{
    const urlParams = {};
    urlParams.pnr = searchParams.get("pnr");
    urlParams.last_name = searchParams.get("last_name");
    urlParams.first_name = searchParams.get("first_name");
    urlParams.airline = searchParams.get("airline");
    if (searchParams.get("booking_code")) urlParams.booking_code = searchParams.get("booking_code");
    storeActions.fetchFlightRecords(urlParams, storeActions.preparePanels);
  }, [searchParams, storeActions]);

  return (
    <>
      <Header/>
      <div className="home-cover-bloc">
        <div className="v-space-10"></div>
        <Container>
         <Grid container>
          <Grid item xs={12}  lg={5} className="big-size-container"> 
            {
              !lockedFlightFound && flightRecords && flightRecords.map((flightRecord, key)=> {
                return (
                  <Fragment key={key}>
                    <Flight flightRecord={flightRecord}/>
                    {
                      flightRecord.layovers.map((layover, key)=>{
                        let prevArrivalDate;
                        if (key === 0) prevArrivalDate = flightRecord.arrival_at;
                        if (key !== 0) prevArrivalDate = layover.arrival_at;
                        return (<Flight flightRecord={layover} prevArrivalDate={prevArrivalDate} key={key}/>)
                      }) 
                    }
                    <Services flightRecord={flightRecord} />
                    <div className="v-space-10"></div>
                  </Fragment>
                )
              })
            }
            { (!flightRecords || lockedFlightFound) && <NoFlightsFound /> }
            { (booking && flightRecords?.length>0 && booking.eprice > 0) && <PlaceOrder booking={booking}/> }
          </Grid>
         </Grid >
        </Container>
        <div className="v-space-10"></div>
      </div>
      <Footer/>
    </>
    )
}


const NoFlightsFound = () => {
  const {t} = useTranslation();
  return (
    <Grid container className="error-container">
      <Grid item xs={12} className="d-f ai-c para1 secondary2-color font-1-medium">
        {t("Flights records not found.")} <ErrorIcon />
      </Grid>
      <div className="v-space-2"></div>
      <Link to={AppRoutes.indexPath+"?personalInfo=1"}> 
        <AppButton 
          sx={{padding:0}}
          buttonLabel={ t("Search Again") }
          buttonType={ButtonTypeEnum.TEXT}
          buttonSize={ButtonSizeEnum.MEDIUM}
          buttonColor={ButtonColorEnum.PRIMARY1}
        />
      </Link>
    </Grid> 
  )
}

const PlaceOrder = ({booking}) => {
  const {t} = useTranslation();
  return (
    <div className="d-f fd-c w-100 sticky-place-order">

     <Link className="d-f fd-c w-100" to={generatePath(AppRoutes.manageBookingPath, { bookingCode: booking.code})} >
       <AppButton 
         sx={{fontFamily: variables["font-1-heavy"]}}
         buttonLabel={ t("Place Order - $"+booking.eprice) }
         buttonType={ButtonTypeEnum.PRIMARY}
         buttonSize={ButtonSizeEnum.MEDIUM}
         buttonColor={ButtonColorEnum.WHITE_BG}
       />
     </Link>
    </div>
  )
}