import React from "react"
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import {timeOnly, mediumDateOnly} from 'utils/helperFunctions'
import useStore from 'store/store'
import './Airport.scss'

export default function Airport({direction, airport, terminal, time})
{
  const {t} = useTranslation();
  const selfCheckinMode = useStore((state)=> state.selfCheckinMode);

  return (
    <>
      <Grid item xs={12} className={`primary1-color para2 font-1-book ${direction === "right"? "r-txt" : "" }`}>
        {airport.city}
      </Grid>
      { !selfCheckinMode && <div className="v-space-2"></div> }
      <Grid item xs={12} className={`upper black-color h4 font-1-heavy ${direction === "right"? "r-txt" : "" }`}>
        {airport.code}
      </Grid>
      { !selfCheckinMode && <div className="v-space-2"></div> }
      <Grid item xs={12} className={`primary1-color para3 font-1-light-bt ${direction === "right"? "r-txt" : "" }`}>
        {t("Terminal ")}{terminal}
      </Grid>
      <div className="v-space-1"></div>
      <Grid item xs={12} className={`primary1-color para1 font-1-heavy ${direction === "right"? "r-txt" : "" }`}>
        {timeOnly(new Date(time))}
      </Grid>
      { !selfCheckinMode && 
        <>
          <div className="v-space-0"></div>
          <Grid item xs={12} className={`primary1-color para2 font-1-book upper ${direction === "right"? "r-txt" : "" }`}>
            {mediumDateOnly(new Date(time))}
          </Grid>
        </>
      }

    </>
    )
}