import React, {useEffect}  from "react"
import {useSearchParams} from 'react-router-dom';
import Idle from "components/SelfCheckin/Idle/Idle"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import AirlineSelection from "components/SelfCheckin/AirlineSelection/AirlineSelection"
import Passenger from "components/SelfCheckin/Passenger/Passenger"
import ScanPassport from "components/SelfCheckin/ScanPassport/ScanPassport"
import BagsScale from "components/SelfCheckin/BagsScale/BagsScale"
import Printing from "components/SelfCheckin/Printing/Printing"
import ConfirmationMessage from "components/SelfCheckin/ConfirmationMessage/ConfirmationMessage"
import Thanks from "components/SelfCheckin/Thanks/Thanks"

import {
  SelfCheckinStepEnum
} from "components/shared/enums/global-enums.tsx";
import useStore from 'store/store'

import './SelfCheckin.scss'

export default function SelfCheckin(){

  let [searchParams, ] = useSearchParams();
  const storeActions = useStore((state)=> state.actions);
  let activeStep = searchParams.get("activeStep");
  const [redirect, ] = useSelfCheckinNafigation();

  useEffect(()=>{
    let TIMEOUT = 60000;
    if (activeStep === SelfCheckinStepEnum.IDLE){
      storeActions.stopScanPassport();
    }
    if (activeStep === SelfCheckinStepEnum.THANKS) TIMEOUT = 25000;
    storeActions.startTimeout(()=>{redirect(-1, SelfCheckinStepEnum.IDLE)}, TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeActions, activeStep]);

  return (
    <div className="self-checkin">
      { (!activeStep || activeStep === SelfCheckinStepEnum.IDLE) && <Idle/> }
      { (activeStep === SelfCheckinStepEnum.AIRLINE_SELECTION) && <AirlineSelection/> }
      { (activeStep === SelfCheckinStepEnum.SCAN_PASSPORT) && <ScanPassport /> }
      { (activeStep === SelfCheckinStepEnum.PASSENGER) && <Passenger typeFor={SelfCheckinStepEnum.PASSENGER}/> }
      { (activeStep === SelfCheckinStepEnum.DANGEROUS_GOODS) && <ConfirmationMessage /> }
      { (activeStep === SelfCheckinStepEnum.BAGS_SCALE) && <BagsScale /> }
      { (activeStep === SelfCheckinStepEnum.ITEMS_TO_PRINT) && <Passenger typeFor={SelfCheckinStepEnum.ITEMS_TO_PRINT}/> }
      { (activeStep === SelfCheckinStepEnum.PRINTING) && <Printing /> }
      { (activeStep === SelfCheckinStepEnum.THANKS) && <Thanks/> }
    </div>
    )
}