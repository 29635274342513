import React, {useMemo, useState}  from "react"

import Grid from '@mui/material/Grid';

import {ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum } from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"
import Links from "components/shared/Links/Links"
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";
import useStore from 'store/store'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import './BookingContact.scss'

const bookingContactSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  country_code: Yup.string().required('Required'),
  phone: Yup.string().matches(/^\d{7,}$/, {message: "Please enter valid number.", excludeEmptyString: false}).required('Required')
});

export default function BookingContact(){
  const {t} = useTranslation();

  const initValues = useMemo(() => ({ 
    email: '',
    country_code: 'SG',
    phone: ''
  }), []);

  const booking = useStore((state)=> state.booking);
  const storeActions = useStore((state)=> state.actions);
  const [countryCode, setCountryCode] = useState("+65");

  const phoneInput = document.getElementsByClassName("PhoneInputInput")[0];
  if (phoneInput) phoneInput.setAttribute("readOnly", "");

  return (
    <Grid  className="bk-contact-container" >
      <Grid className="primary1-color para1 font-1-medium c-txt">
        {t("Thank you, your booking is almost complete!")}
      </Grid>
      <div className="v-space-7"></div>
      <Grid className="primary1-color para1 font-1-heavy c-txt">
        {t("Please let us know your contact  details before paying.")}
      </Grid>
      <div className="v-space-5"></div>
      <Formik
        initialValues={initValues}
        validationSchema={bookingContactSchema}
        onSubmit={(values)=>{
          values.code=booking.code;
          values.country_code=countryCode;
          storeActions.updateBooking(values);
          }
        }
      >
      {({ errors, touched }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Field  id="email" className="field w-100 bg-white-color para2 font-1-book" name="email" placeholder={t('E-mail address')} />
              <div className="v-space-1"></div>
              {errors.email && touched.email ? <div className="error-color">{errors.email}</div> : null}
            </Grid>

            <Grid container spacing={2} item xs={12} lg={12}>
              <Grid item xs={4} >
                <PhoneInput  international countryCallingCodeEditable={false}
                  defaultCountry={initValues.country_code} className="field bg-white-color indicatif" value={countryCode} onChange={ setCountryCode }/>

                <div className="v-space-1"></div>
                {errors.country_code && touched.country_code ? <div className="error-color">{errors.country_code}</div> : null}
              </Grid>
              <Grid  item  xs={8} >
                <Field  id="phone" className="field w-100 bg-white-color para2 font-1-book" name="phone" placeholder={t('Mobile number')} />
                <div className="v-space-1"></div>
                {errors.phone && touched.phone ? <div className="error-color">{errors.phone}</div> : null}
              </Grid>
            </Grid>

            <Grid item lg={12}>
                <AppButton 
                  sx={{minWidth: '60%', fontFamily: variables["font-1-heavy"]}}
                  buttonLabel={ t("Proceed") }
                  buttonType={ButtonTypeEnum.PRIMARY}
                  buttonSize={ButtonSizeEnum.MEDIUM}
                  buttonColor={ButtonColorEnum.PRIMARY1_BG}
                  actionType="submit"
                />
            </Grid>

            <div className="v-space-7"></div>
            <Grid item lg={12}>
              <Links login={true} register={true}   />
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>


    </Grid>
    )
}