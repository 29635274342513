import React from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"

import Bags from "./Bags/Bags"
import Location from "./Location/Location"

import DateTime from "./DateTime/DateTime"

import TicketDelimiter from "components/shared/TicketDelimiter/TicketDelimiter"

import useStore from 'store/store'

// import arrowBottom from 'assets/images/arrowBottom.png'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import './ServiceForm.scss'



export default function ServiceForm({flightRecord, typeFor}){

  const {t} = useTranslation();

  const bagsPanel = useStore((state)=> state.bagsPanel);
  const locationPanel = useStore((state)=> state.locationPanel);
  const dateTimePanel = useStore((state)=> state.dateTimePanel);

  const storeActions = useStore((state)=> state.actions);

  return (
    <Box className="sf-container">
      {
        bagsPanel[flightRecord.id] && 
        <>
          <Bags typeFor={typeFor} flightRecord={flightRecord} />
          { !locationPanel[flightRecord.id]  && 
            <Grid item xs className={`d-f jc-c fd-c col-modifier ${typeFor}`}>
              <AppButton 
                buttonLabel={ 
                  <>
                  <Box className="d-f jc-c cmn-color fd-c">
                    {t("Where")}
                    <ArrowBackIosNewIcon className="c-el" sx={{transform: 'rotate(-90deg)'}}/>
                  </Box>
                  </> }
                buttonType={ButtonTypeEnum.TEXT}
                buttonSize={ButtonSizeEnum.MEDIUM}
                buttonColor={ButtonColorEnum.SECONDARY2}
                onClickFunc={()=>storeActions.togglePanel("locationPanel", flightRecord.id, true)}
              />
              <div className="v-space-5"></div>
            </Grid>
          }
        </>
      }
      {locationPanel[flightRecord.id] && 
        <>
          <TicketDelimiter theme={typeFor}/>
          <Location typeFor={typeFor} flightRecord={flightRecord} />
          { !dateTimePanel[flightRecord.id] && 
            <Grid item xs className={`d-f jc-c fd-c  col-modifier ${typeFor}`}>
              <AppButton 
                buttonLabel={ 
                  <>
                  <Box className="d-f jc-c cmn-color fd-c">
                    {t("When")}
                    <ArrowBackIosNewIcon className="c-el" sx={{transform: 'rotate(-90deg)'}}/>
                  </Box>
                  </> }
                buttonType={ButtonTypeEnum.TEXT}
                buttonSize={ButtonSizeEnum.MEDIUM}
                buttonColor={ButtonColorEnum.SECONDARY2}
                onClickFunc={()=>storeActions.togglePanel("dateTimePanel", flightRecord.id, true)}
              />
              <div className="v-space-5"></div>
            </Grid>
          }
        </>
      }
      
      {
        dateTimePanel[flightRecord.id] &&
        <>
          <TicketDelimiter theme={typeFor}/>
          <DateTime typeFor={typeFor}  flightRecord={flightRecord}/>
        </>
      }

    </Box>
    )
}