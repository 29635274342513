import React  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import Grid from '@mui/material/Grid';
import logo from "assets/images/logo.png"
import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, SelfCheckinStepEnum
} from "components/shared/enums/global-enums.tsx";

import AppButton from "components/shared/AppButton/AppButton"
import './Footer.scss'

export default function Footer({backStep=-1, backAddFunc, forwordStep=1, forwordAddFunc, forwordTitle="Proceed", forceFooter=false}){
  const {t} = useTranslation();
  const [redirect, activeStep] = useSelfCheckinNafigation();

  return (

    <Grid className="d-f jc-r floating-footer" container spacing={2}>
      <Grid item xs={4} >
        
        {((activeStep > SelfCheckinStepEnum.SCAN_PASSPORT && activeStep <= SelfCheckinStepEnum.ITEMS_TO_PRINT) || forceFooter) && <AppButton 
          sx={{paddingLeft: 5, paddingRight: 5}}
          buttonLabel={ t("Cancel") }
          buttonType={ButtonTypeEnum.PRIMARY}
          buttonSize={ButtonSizeEnum.SMALL}
          buttonColor={ButtonColorEnum.SECONDARY1_BG}
          onClickFunc={()=>{
            if (backStep !== 0) redirect(backStep);
            try { backAddFunc(); } catch(e) {}
            }
          }
        />}
        
      </Grid>
      <Grid item xs={4}  >
        <div className="c-txt">
          <img src={logo} alt=""/>
          <div className="font-1-medium para1 uppercase white-color">{t("PORTAL")}</div>
        </div>
      </Grid>
      <Grid item xs={4} className="d-f jc-r" >
        {((activeStep >= SelfCheckinStepEnum.PASSENGER && activeStep <= SelfCheckinStepEnum.ITEMS_TO_PRINT) || forceFooter)  &&  <AppButton 
          sx={{paddingLeft: 5, paddingRight: 5}}
          buttonLabel={ t(forwordTitle) }
          buttonType={ButtonTypeEnum.PRIMARY}
          buttonSize={ButtonSizeEnum.SMALL}
          buttonColor={ButtonColorEnum.SECONDARY1_BG}
          onClickFunc={()=>{
            if (forwordStep !== 0) redirect(forwordStep);
            try { forwordAddFunc(); } catch(e) {}
            }
          }
        />}
      </Grid>
    </Grid>
    
    )
}