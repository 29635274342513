import React, {useEffect}  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import MessageScreen from "components/SelfCheckin/MessageScreen/MessageScreen"
import { useTranslation } from 'react-i18next';
import useStore from 'store/store'

import './ScanPassport.scss'

export default function ScanPassport(){
  const {t} = useTranslation();

  const [redirect, ] = useSelfCheckinNafigation();
  const pendingPassScan = useStore((state)=> state.pendingPassScan);
  const storeActions = useStore((state)=> state.actions);

  let text= t("PLEASE SCAN YOUR PASSPORT");
  if (pendingPassScan?.first_name && pendingPassScan?.last_name){
    text = t("PLEASE SCAN PASSPORT OF ")+pendingPassScan.first_name+" "+pendingPassScan.last_name;
  }

  useEffect(()=>{
    storeActions.scanPassport(redirect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessageScreen message={text} />    
    )
}