import {DateTimeFormat, FlightStepType} from "components/shared/enums/global-enums.tsx";


export function dateOnly(date) {
  return date.toLocaleDateString('en-GB', DateTimeFormat.DATE);
}

export function shortDateOnly(date) {
  return date.toLocaleDateString('en-GB', DateTimeFormat.SHORT_DATE);
}

export function mediumDateOnly(date) {
  return date.toLocaleDateString('en-GB', DateTimeFormat.MEDIUM_DATE);
}

export function timeOnly(date) {
  return date.toLocaleString('en-GB', DateTimeFormat.TIME_24);
}


export function exactAirport(typeFor, flightRecord) {
  if (typeFor===FlightStepType.COLLECTION){
    return flightRecord.departure_airport;
  }else{
    if (flightRecord.layovers.length > 0) return flightRecord.layovers[0].arrival_airport; 
    else return flightRecord.arrival_airport; 
  } 
}

export function msToTime(duration) {
  var minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  minutes = (minutes < 10) ? "0" + minutes : minutes;

  return hours + "h" + minutes;
}

export function diffInSeconds(date1, date2) {
  return Math.round((date2.getTime() - date1.getTime()) / (1000));
}

export function diffInDays(date1, date2) {
  return Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
}


// export function calculatePrice(bags, childs, others, config) {
//   let bags_formula = config.bags_formula;
//   let childs_formula = config.childs_formula;
//   let others_formula = config.others_formula;

//   let single_price = 0;
//   single_price += eval(bags_formula);
//   single_price += eval(childs_formula);
//   single_price += eval(others_formula);
//   return single_price;
// }

// export function nearestSmallerTime(dateTime) {
//   if (dateTime.getMinutes()<30) {
//     dateTime.setMinutes(0); 
//   }
//   else if (dateTime.getMinutes()>30){ 
//     dateTime.setMinutes(30);
//   }
//   return dateTime;
// }

// export function nearestGreaterTime(dateTime) {
//   if (dateTime.getMinutes()<30) {
//     dateTime.setMinutes(30); 
//   }
//   else if (dateTime.getMinutes()>30){ 
//     dateTime.setHours(dateTime.getHours()+1); 
//     dateTime.setMinutes(0);
//   }
//   return dateTime;
// }
