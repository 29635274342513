import React from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import Grid from '@mui/material/Grid';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, SelfCheckinStepEnum
} from "components/shared/enums/global-enums.tsx";

import useStore from 'store/store'
import variables from "utils/_variables.module.scss";
import AppButton from "components/shared/AppButton/AppButton"

export default function BagsCount({sCC, index}){
  const {t} = useTranslation();
  let [redirect, ] = useSelfCheckinNafigation();
  const storeActions = useStore((state)=> state.actions);


  return (
    <Grid item xs sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 1.5, paddingBottom: 0.5}} className="d-f fd-c jc-c bg-secondary2-color  left-slot right-slot">

        <div className="primary1-color para1 font-1-book">{t("How many items would you like to check-in?")}</div>
        <div className="v-space-2"></div>
        <div className="primary3-color para3 font-1-book">{t("Max weight 32kg, additional bags may incur extra charges")}</div>
        <div className="v-space-5"></div>
        <Grid item className="d-f ai-c jc-c" >
          <AppButton 
              buttonLabel={ <RemoveIcon /> }
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonSize={ButtonSizeEnum.VERY_SMALL}
              buttonColor={ButtonColorEnum.primary1_BG}
              onClickFunc={()=>{
                  storeActions.setSelfCheckinChoices(index, "bags", -1);
                  storeActions.startTimeout(()=>{redirect(-1, SelfCheckinStepEnum.IDLE)}, 70000);
                }
              }
            />  
            <span className={`para1 font-1-medium primary1-color bags-counter`}>
              {sCC.bags.length}
            </span>
          <AppButton 
              buttonLabel={ <AddIcon /> }
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonSize={ButtonSizeEnum.VERY_SMALL}
              buttonColor={ButtonColorEnum.primary1_BG}
              onClickFunc={()=>{
                  storeActions.setSelfCheckinChoices(index, "bags", 1)
                  storeActions.startTimeout(()=>{redirect(-1, SelfCheckinStepEnum.IDLE)}, 70000);
                }
              }
            />      
        </Grid>
        <div className="v-space-1"></div>
        <AppButton 
          sx={{fontSize: 20, fontFamily: variables["font-1-heavy"]}}
          buttonLabel={t("DE-SELECT PASSENGER")}
          buttonType={ButtonTypeEnum.TEXT}
          buttonSize={ButtonSizeEnum.LARGE}
          buttonColor={ButtonColorEnum.WHITE}
          onClickFunc={()=>{storeActions.setSelfCheckinChoices(index, "typeFor", SelfCheckinStepEnum.PASSENGER)
            storeActions.setSelfCheckinChoices(index, "bags", -5)
            }
          }
        />
    </Grid>
    )
}