import React  from "react"

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import visa from 'assets/images/visa.png'
import mastercard from 'assets/images/mastercard.png'


import './StoredCards.scss'

export default function StoredCards(){

  const {t} = useTranslation();



  const storedCards = [
    {
      img: visa,
      name: 'Visa 1234'
    },
    {
      img: mastercard,
      name: 'Mastercard 5678'
    }
  ];

  return (
    <Grid  >

      <div className="para2 black-color font-1-book-bt">
        {t("Credit and Debit Cards")}
      </div>
      <div className="v-space-3"></div>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={storedCards[0].name}
        name="radio-buttons-group"
        >
        {
          storedCards.map ((storedCard, key)=>{
            return (
              <Grid key={key}   className="s-card" >
                <Grid item xs={1.5} className="d-f ai-c para3 font-1-light-bt">
                  <img src={storedCard.img} alt="" /> 
                </Grid>
                <Grid item xs={8} className="d-f ai-c para3 font-1-light-bt">
                  {storedCard.name}
                </Grid>
                <Grid item xs className="d-f ai-c jc-r">
                  <FormControlLabel sx={{margin:0, padding:0}} value={storedCard.name} control={<Radio />} />
                </Grid>

              </Grid>
            )
          })
        }
        
      </RadioGroup>

    </Grid>
    )
}