import React, {useState} from "react"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Links from 'components/Landing/Links/Links';
import AccessForm from 'components/Landing/AccessForm/AccessForm';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  AudienceTypes
} from "components/shared/enums/global-enums.tsx";

import useStore from "store/store";

import whiteBurd  from 'assets/images/whiteBurd.png'
import './Partner.scss'

const rCSchema = Yup.object().shape({
  email: Yup.string()
   .required('Required').email(),
  name: Yup.string()
   .required('Required'),
  organization: Yup.string()
   .required('Required')
});

const rCInitValues = { 
  name: '',
  email: '',
  organization: ''
};

const rCInputs = [
  {
    name: "name",
    placeholder: "What's your name?"
  },
  {
    name: "organization",
    placeholder: "Who do you work for?"
  },
  {
    name: "email",
    placeholder: "What’s your email address?"
  }, 
]


const aCSchema = Yup.object().shape({
  access_code: Yup.string()
   .required('Required')
});

const aCInitValues = { 
  access_code: ''
};

const aCInputs = [
  {
    name: "access_code",
    placeholder: "Enter your code here"
  }
]


export default function Partner({setStep}){
  
  const {t} = useTranslation();

  const [requestCode, setRequestCode] = useState(false);

  const storeActions = useStore((state)=> state.actions);
 
  return (
    <div className="access-code-block">
      <div className="v-space-10"></div>
      <div className="v-space-5"></div>
      <Container>

        <Grid container className="white-color" > 

          <Grid container item xs spacing={2}>
            <Grid container item xs={12}>
              <div className="h5 font-1-heavy ">        
                {t("WE’RE GLAD YOU’RE HERE, PARTNER! ")}
              </div> 

              <div className="v-space-8"></div>
              <div className="para1 font-1-heavy ">        
                {t("TRY THE UI, GET IN TOUCH—WE HAVE OPTIONS FOR YOU.")}
              </div> 
              
              <div className="v-space-8"></div>
            </Grid>

            {
              !requestCode && <AccessForm validationSchema={aCSchema} initValues={aCInitValues} inputs={aCInputs} action={storeActions.getUserByCode} title={t("IF YOU ALREADY HAVE AN ACCESS CODE, ENTER IT BELOW")} btnTitle={t("TRY")} />
            }

            {
              requestCode && <AccessForm type={AudienceTypes.PARTNER} validationSchema={rCSchema} initValues={rCInitValues} inputs={rCInputs} action={storeActions.createUser} title={t("TO REQUEST AN ACCESS CODE, ENTER YOUR DETAILS BELOW")} btnTitle={t("SEND")}  />
            }

            <Grid container className="d-f" item xs={12} >

              <Links requestCode={requestCode} setRequestCode={setRequestCode} linksFor={AudienceTypes.PARTNER} setStep={setStep} />
            </Grid>
            
          </Grid>

          <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'flex' } }} className=" jc-c ai-b">
            <img src={whiteBurd} alt="" width="120" />
          </Grid>

        </Grid>

        <div className="v-space-5"></div>
      
      </Container>
      <div className="v-space-10"></div>
    </div>
    )
}