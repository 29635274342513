import React from "react"
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";

import AppButton from "components/shared/AppButton/AppButton"

import './CTA.scss'



export default function CTA({slideUp, setStep}){
  
  const {t} = useTranslation();

  const btnStyle= {
    fontSize: {xs:'16px',md:'20px'}, 
    paddingLeft:1, 
    paddingRight:1
  }

  const transitionProps = slideUp ? {bottom: '0px'} : {};

  return (
    <div className="cta-block" style={transitionProps}>
      <div className="v-space-10"></div>
      <Grid item className="white-color h5 font-1-medium c-txt" > 
        
        {t("THANKS FOR STOPPING BY AND WELCOME ABOARD A BETTER TRAVEL EXPERIENCE!")}
        <br/> 
        {t("TELL US MORE ABOUT WHO YOU ARE:")}
      
        <div className="v-space-10"></div>
        <div className="d-f jc-c">

          <AppButton 
            sx={btnStyle}
            buttonLabel={ t("BUSINESS PARTNER") }
            onClickFunc={()=>setStep(1)}
            buttonType={ButtonTypeEnum.OUTLINE}
            buttonSize={ButtonSizeEnum.MEDIUM}
            buttonColor={ButtonColorEnum.BLACK_BG_WHITE}
          />

          <div className="h-space-1"></div>
          <div className="h-space-1"></div>
          <div className="h-space-1"></div>

          <AppButton 
            sx={btnStyle}
            buttonLabel={ t("TRAVELLER") }
            onClickFunc={()=>setStep(2)}
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.MEDIUM}
            buttonColor={ButtonColorEnum.WHITE_BG_BLACK}
          />


        </div>
        <div className="v-space-5"></div>

      </Grid>
      <div className="v-space-10"></div>
    </div>
    )
}