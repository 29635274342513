import React, {useEffect, useState}  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import { useTranslation } from 'react-i18next';
import MessageScreen from "components/SelfCheckin/MessageScreen/MessageScreen"
import ConfirmationMessage from "components/SelfCheckin/ConfirmationMessage/ConfirmationMessage"
import { SelfCheckinStepEnum } from "components/shared/enums/global-enums.tsx";
import useStore from 'store/store'

import './Printing.scss'

export default function Printing(){
  const {t} = useTranslation();

  const [redirect, ] = useSelfCheckinNafigation();
  const flightRecords = useStore((state)=> state.flightRecords);
  const selfCheckinChoices = useStore((state)=> state.selfCheckinChoices);
  const storeActions = useStore((state)=> state.actions);
  const [message, setMessage] = useState(null);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(false);
  const [hasDocuments, setHasDocuments] = useState(true);

  useEffect(()=>{
    function lanchBagTagPrint(delai){
      setTimeout(()=> {
        setArrowRight(false);
        setArrowLeft(true);
        setMessage(t("PRINTING BAGGAGE TAG"))
        } 
        , delai);
    }

    function printing(){
      if (!selfCheckinChoices.find(el => (el.printBoardingPass || el.printBaggageTags))){
        return setHasDocuments(false); 
      }
      storeActions.printElements(flightRecords, selfCheckinChoices, redirect);
      let redAfter = 10000;
      if (selfCheckinChoices.find(el => (el.printBoardingPass))){
        setMessage(t("PRINTING BOARDING PASS"));
        setArrowRight(true);
        if (selfCheckinChoices.find(el => (el.printBaggageTags))) {
          lanchBagTagPrint(10000);
          redAfter+=10000;
        }
      }else if(selfCheckinChoices.find(el => (el.printBaggageTags))){
        lanchBagTagPrint(0);
      }
      setTimeout(() => redirect(1), redAfter);
    }

    printing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    
      hasDocuments ?
      (<MessageScreen message={message} arrowLeft={arrowLeft} arrowRight={arrowRight}/>) :
      (<ConfirmationMessage typeFor={SelfCheckinStepEnum.PRINTING} forceFooter={true}/>)  
     
    )
}