import Api from 'utils/api'
import i18n from 'utils/i18n'

export const bookingSlice = ((set, get) => ({
  booking: null,

  actions: {

    updateBooking: async (params) => {
      get().actions.startLoading();
      const api = new Api();
      let data = {};
      data.booking = params;
      api.updateBooking(data)
        .then((response) => {
          set((state) => ({ booking: response.data.booking }));
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },

    fetchBooking: async (params) => {
      get().actions.startLoading();
      const api = new Api();

      api.getBooking(params)
        .then((response) => {
          set((state) => ({ booking: response.data.booking }));
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        // get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    }
  }

}))