import React from "react"

import ServiceForm from "./ServiceForm/ServiceForm"
import Actions from "./Actions/Actions"

import {FlightStepType } from "components/shared/enums/global-enums.tsx";
import useStore from 'store/store'
import './Services.scss'

export default function Services({flightRecord}){
  const activeStepPanel = useStore((state)=> state.activeStepPanel);

  const defaultBookingDetailValues = useStore((state)=> state.defaultBookingDetailValues);
  if (!flightRecord.booking_detail){
    flightRecord.booking_detail = defaultBookingDetailValues;
  }


  return (
    <>
      
      {
        activeStepPanel[flightRecord.id] === FlightStepType.COLLECTION &&
        <ServiceForm  typeFor={FlightStepType.COLLECTION} flightRecord={flightRecord}/>
      }
      
      {
        activeStepPanel[flightRecord.id] === FlightStepType.DELIVERY &&
        <ServiceForm  typeFor={FlightStepType.DELIVERY} flightRecord={flightRecord}/>
      }
      
      <Actions flightRecord={flightRecord} />

    </>
    )
}