
import './TicketDelimiter.scss'


export default function TicketDelimiter({theme, halfPart}){
  return (
    <>
      { (!halfPart || halfPart === "top") &&
        <div className={`delimiter top ${theme}`} >
          <div className="left">
          </div>
          <div className="right">
          </div>
        </div>
      }
      {
        (!halfPart || halfPart === "bottom") &&
        <div className={`delimiter bottom ${theme}`}>
          <div className="left">
          </div>
          <div className="right">
          </div>
        </div>
      }
    </>
    )
}