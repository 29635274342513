import React, {useEffect} from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import MessageScreen from "components/SelfCheckin/MessageScreen/MessageScreen"
import { SelfCheckinStepEnum } from "components/shared/enums/global-enums.tsx";
import { useTranslation } from 'react-i18next';
import stairs from "assets/images/stairs.png"
import useStore from 'store/store'
import './Thanks.scss'

export default function Thanks(){
  const {t} = useTranslation();

  const [redirect, ] = useSelfCheckinNafigation();
  const selfCheckinChoices = useStore((state)=> state.selfCheckinChoices);

  useEffect(()=>{
    if (selfCheckinChoices.length===0){
      redirect(0, SelfCheckinStepEnum.IDLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div onKeyDown={()=>redirect(-1, SelfCheckinStepEnum.IDLE)} onClick={()=>redirect(-1, SelfCheckinStepEnum.IDLE)}>
      <MessageScreen message={t("THANK YOU FOR CHOOSING PORTAL")} subMessage={t("Enjoy your flight!")} mimg={stairs} />
    </div>
    )
}