import React, {useState} from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useSearchParams} from 'react-router-dom';

import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";

import AppButton from "components/shared/AppButton/AppButton"
import Links from "components/shared/Links/Links"

import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";

import './ServiceSummary.scss'

export default function ServiceSummary(){

  const {t} = useTranslation();

  const [isGreating, setIsGreating] = useState(true);

  let [, setSearchParams] = useSearchParams();

  function showChooseAirlinePanel(){
    setSearchParams(params => {
      params.set("personalInfo", 1);
      return params;
    });
  }


  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setIsGreating(false);
  //   }, 2000)
  // }, []);

  return (
    <Box className="ss-container">

      {
        isGreating && 
        <Grid md={8}>
          <div className="v-space-2"></div>
          <Typography variant="span" className="h5 font-1-heavy white-color">
            {t("Your trip should start when and where you want it to.")}
          </Typography>
          <div className="v-space-2"></div>
        </Grid>
      }
      {
        !isGreating && 
        <>
          <Typography variant="span" className="h5 font-1-heavy primary1-color">
            {t("Check in on your terms:")}
          </Typography>
          <div className="v-space-1"></div>

          <Typography variant="span" className="h5 font-1-heavy white-color">
            {t(" anywhere, anytime.")}
          </Typography>

          <div className="v-space-7"></div>

          <Typography variant="span" className="para1 font-1-heavy primary1-color">
            {t("Let us worry about your baggage, so you don’t have to. ")}
          </Typography>

          <div className="v-space-7"></div>
          <AppButton 
            sx={{fontSize: variables["para1-bis"]+'px', fontFamily: variables["font-1-heavy"], padding:'0 50px'}}
            buttonLabel={ t("Book Now") }
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.MEDIUM}
            buttonColor={ButtonColorEnum.PRIMARY_BG}
            onClickFunc={showChooseAirlinePanel}
          />

          <div className="v-space-5"></div>
        </>
      }
      

      

      <Links services={true} isGreating={isGreating} setIsGreating={setIsGreating}/>
    </Box>
    )
}