import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ProtectedRoute from "components/shared/ProtectedRoute/ProtectedRoute"
import Home from "views/Home/Home"
import Landing from "views/Landing/Landing"
import Page from "views/Page/Page"
import ScaleTest from "views/ScaleTest/ScaleTest"
import SelfCheckin from "views/SelfCheckin/SelfCheckin"
import FlightsList from "views/FlightsList/FlightsList"
import ManageBooking from "views/ManageBooking/ManageBooking"
import {ConfigEnum} from "components/shared/enums/global-enums.tsx";
import Loading from "components/shared/Loading/Loading"
import {AppRoutes} from "utils/routes";
import landingVideo from 'assets/videos/landing.mp4'
import useStore from 'store/store'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import 'utils/i18n';

import './App.scss';

function App() {
  const {t} = useTranslation();
  const loadingProcesses = useStore((state)=> state.loadingProcesses);

  const snackMessageShown = useStore((state)=> state.snackMessageShown);
  const snackMessageType = useStore((state)=> state.snackMessageType);
  const snackMessage = useStore((state)=> state.snackMessage);
  const storeActions = useStore((state)=> state.actions);
  
  useEffect(()=>{
    storeActions.fetchConfigs({categories: [ConfigEnum.COLLECTION, ConfigEnum.DELIVERY, ConfigEnum.BAGS_LIMIT, ConfigEnum.PRICES]});
    storeActions.reloadUser();
  }, [storeActions])


  return (
    <>
      <video preload="auto" style={{display:'none'}} src={landingVideo} playsInline /> 
      <BrowserRouter>
        <Routes>

          <Route index path={AppRoutes.indexPath}  element={<ProtectedRoute elm={<Home landingVideo={landingVideo}/>}/>}></Route>

          <Route  path={"Landing"} element={<Landing />}></Route>
          <Route path={AppRoutes.flightsPath} element={<ProtectedRoute elm={<FlightsList />}/>}></Route>
          <Route path={AppRoutes.selfCheckinPath} element={<ProtectedRoute elm={<SelfCheckin />}/>}></Route>
          <Route path={AppRoutes.manageBookingPath} element={<ProtectedRoute elm={<ManageBooking />}/>}></Route>
          <Route path={AppRoutes.pagePath} element={<ProtectedRoute elm={<Page />}/>}></Route>
          <Route path={AppRoutes.scaleTestPath} element={<ProtectedRoute elm={<ScaleTest />}/>}></Route>
          
        </Routes>
      </BrowserRouter>

      {
        loadingProcesses > 0 && 
        <Loading theme={window.location.pathname === AppRoutes.selfCheckinPath? "dark" : ""} />
      }

      {
        !!snackMessageShown &&
        <Snackbar
          open={!!snackMessageShown}
          anchorOrigin={{ vertical: 'bottom', horizontal:'right' }}
          container={document.body}
          autoHideDuration={6000}
          onClose={()=>storeActions.setSnackMessage({show: false})}
        >
          <Alert  severity={snackMessageType||"error"} variant="filled"  sx={{ width: '100%', fontSize: 18 }}>
            {snackMessage||t("Something went wrong")}
          </Alert>
        </Snackbar>
      }
    </>
  );
}

export default App;
