import React from "react"

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import bagImage from 'assets/images/bag.png'
import calculatorImage from 'assets/images/calculator.png'

import { useTranslation } from 'react-i18next';

import {
  HomeSwitchMenu
} from "components/shared/enums/global-enums.tsx";


import './SwitchMenu.scss'

export default function SwitchMenu({activeTab, handleSwitchMenu}){

  const {t} = useTranslation();

  return (
    <>
      <Grid container className="sm-container ">
        <Grid  className={`pointer d-f ai-c fd-c menu-item ${(!activeTab || activeTab === HomeSwitchMenu.BOOK_SERVICE ) ? 'active' : ''}`} onClick={()=>handleSwitchMenu(HomeSwitchMenu.BOOK_SERVICE)}>
          <img src={bagImage} alt="" className="img1"/> 
          <div className="v-space-1"></div>
          <Typography variant="span" className="title para1 font-1-heavy ">
            {t('Book service')}
          </Typography>

        </Grid>
        <Grid className={`pointer d-f ai-c fd-c menu-item ${activeTab === HomeSwitchMenu.CHECK_PRICES ? 'active' : ''}`} onClick={()=>handleSwitchMenu(HomeSwitchMenu.CHECK_PRICES)}>
          <img src={calculatorImage} alt="" className="img2"/> 
          <div className="v-space-1"></div>
          <Typography variant="span" className="title para1 font-1-heavy ">
            {t('Check prices')}
          </Typography>
        </Grid>
      </Grid>
    </>
    )
}