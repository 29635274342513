import React from "react"

import FlightHeader from "./FlightHeader/FlightHeader"
import Passenger from "./Passenger/Passenger"
import FlightDetail from "./FlightDetail/FlightDetail"
import {diffInDays} from 'utils/helperFunctions'
import useStore from 'store/store'

export default function Flight({flightRecord, prevArrivalDate}){

  let diffDays = diffInDays(new Date(), new Date(flightRecord.departure_at));

  const selfCheckinMode = useStore((state)=> state.selfCheckinMode);
  
  return (
    <>
      {
        !prevArrivalDate && !selfCheckinMode && 
        <><div className="para2 black-color font-1-medium">{"In "+diffDays+" Days"}</div>
        <div className="v-space-1"></div></>
      }
      <FlightHeader flightRecord={flightRecord} prevArrivalDate={prevArrivalDate}/>
      <Passenger flightRecord={flightRecord} />
      <FlightDetail flightRecord={flightRecord} />
    </>
    )
}