import { createTheme } from "@mui/material";
import variables from "utils/_variables.module.scss";

export const buttonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#000",
          borderRadius: "10px",
          textTransform: "none",
          textAlign: "center",
          lineHeight: "24px",
          fontSize: "16px",
        },
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            color: variables["white-color"],
            background: variables["primary1-color"],
            fontFamily: variables["font-1-medium"],
            fontWeight: 400,
            "&:hover": {
              backgroundColor: variables["primary1-color"],
            },
            "&:active": {
              backgroundColor: variables["black-color"],
            },
          },
        },
        
        {
          props: { variant: "text" },
          style: {
            color: variables["white-color"],
            textTransform: "none",
            textAlign: "center",
            fontFamily: variables["font-1-medium"],
            fontWeight: '400 !important',
            "&:hover": {
              backgroundColor: '#00000000'
            },
          },
        },
        {
          props: { variant: "outline" },
          style: {
            color: variables["white-color"],
            fontFamily: variables["font-1-medium"],
            border: '2px solid '+variables["white-color"],
            fontWeight: 400,
            "&:hover": {
              backgroundColor: variables["primary3-color"],
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "14px 50px",
            fontSize: "24px"
          },
        },
        {
          props: { size: "medium" },
          style: {
            height: 44,
            fontSize: "20px",
          },
        },
        {
          props: { size: "small" },
          style: {
            height: 35,
            fontSize: "16px",
          },
        },
        {
          props: { size: "very-small" },
          style: {
            padding: "0px",
            height: "32px",
            width: "32px",
            minWidth: "32px",
            lineHeight: "32px",
            borderRadius: "3px",
            fontSize: "24px",
          },
        },
        {
          props: { variant: "primary", coloro: "white-bg" },
          style: {
            background: variables["white-color"],
            color: variables["primary1-color"],
            "&:hover": {
              backgroundColor: variables["white-color"],
            },
          },
        },

        {
          props: { variant: "primary", coloro: "secondary2-bg" },
          style: {
            background: variables["secondary2-color"],
            color: variables["primary1-color"],
            "&:hover": {
              backgroundColor: variables["secondary2-color"],
            },
          },
        },

        {
          props: { variant: "primary", coloro: "secondary1-bg" },
          style: {
            background: variables["secondary1-color"],
            color: variables["primary1-color"],
            "&:hover": {
              backgroundColor: variables["secondary1-color"],
            },
          },
        },

        {
          props: { variant: "primary", coloro: "primary3-bg" },
          style: {
            background: variables["primary3-color"],
            color: variables["white-color"],
            "&:hover": {
              backgroundColor: variables["primary3-color"],
            },
          },
        },

        {
          props: { variant: "primary", coloro: "primary2-bg" },
          style: {
            background: variables["primary2-color"],
            color: variables["white-color"],
            "&:hover": {
              backgroundColor: variables["primary2-color"],
            },
          },
        },

        {
          props: { variant: "text", coloro: "white" },
          style: {
            color: variables["white-color"],
          },
        },

        {
          props: { variant: "text", coloro: "black" },
          style: {
            color: variables["black-color"],
          },
        },

        {
          props: { variant: "text", coloro: "secondary2" },
          style: {
            color: variables["secondary2-color"],
            "&:hover": {
              color: variables["white-color"],
              backgroundColor: '#00000000'

            },
          },
        },

        {
          props: { variant: "text", coloro: "primary1" },
          style: {
            color: variables["primary1-color"],
            "&:hover": {
              color: variables["primary2-color"],
              backgroundColor: '#00000000'

            },
          },
        },

        {
          props: { variant: "text", coloro: "primary3" },
          style: {
            color: variables["primary3-color"],
            "&:hover": {
              color: variables["secondary1-color"],
              backgroundColor: '#00000000'

            },
          },
        },

        {
          props: { variant: "primary", coloro: "white-bg-secondary2" },
          style: {
            color: variables["secondary2-color"],
            backgroundColor: variables["white-color"],
            "&:hover": {
              backgroundColor: variables["white-color"]
            },
          },
        },

        {
          props: { variant: "primary", coloro: "white-bg-black" },
          style: {
            color: variables["black-color"],
            backgroundColor: variables["white-color"],
            "&:hover": {
              backgroundColor: variables["primary3-color"]
            },
          },
        },

        {
          props: { variant: "primary", coloro: "secondary2-bg-white" },
          style: {
            color: variables["white-color"],
            backgroundColor: variables["secondary2-color"],
            "&:hover": {
              backgroundColor: variables["secondary2-color"]
            },
          },
        },
        
        {
          props: { variant: "primary", coloro: "black-bg-white" },
          style: {
            color: variables["white-color"],
            backgroundColor: variables["black-color"],
            "&:hover": {
              backgroundColor: variables["black-color"]

            },
          },
        },
        
        
      ],
    },
  },
});

/* Enable the new theme overrides to be used wihtout errors */
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    text: true;
    outline: true;
  }
}
