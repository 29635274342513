import React from "react"

import Grid from '@mui/material/Grid';
import {useSearchParams} from 'react-router-dom';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import { useTranslation } from 'react-i18next';
import {exactAirport} from 'utils/helperFunctions'

import AppButton from "components/shared/AppButton/AppButton"
import useStore from 'store/store'

export default function Save({flightRecord, bookingDetail, typeFor}){
  const {t} = useTranslation();
  const storeActions = useStore((state)=> state.actions);

  let [searchParams] = useSearchParams();
  let bookingCode = searchParams.get("booking_code");


  return (
      <Grid item className="d-f fd-c">
        <div className="para3 white-color c-txt w-100 font-1-light-bt">{exactAirport(typeFor, flightRecord).city}</div>
        <AppButton 
        buttonLabel={ t("Save Details") }
        buttonType={ButtonTypeEnum.TEXT}
        buttonSize={ButtonSizeEnum.MEDIUM}
        buttonColor={ButtonColorEnum.WHITE}
        buttonEndIcon={<ArrowDropUpIcon/>}
        onClickFunc={()=>{
            storeActions.saveBookingDetail(
              { flightRecord: flightRecord, bookingDetail: bookingDetail, bookingCode: bookingCode, typeFor: typeFor}, 
              ()=>storeActions.toggleFlightStepPanel(flightRecord.id, '')
            );
          }
        }/>
      </Grid>
    )
}