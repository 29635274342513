import Button from "@mui/material/Button";
import {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "components/shared/enums/global-enums.tsx";
import { ThemeProvider } from "@emotion/react";
import { buttonTheme } from "./utils/button-theme.tsx";

export interface buttonProps {
  buttonLabel: string;
  buttonType: ButtonTypeEnum;
  isDisabled?: boolean;
  buttonSize: ButtonSizeEnum;
  onClickFunc?: () => void;
  buttonEndIcon?: React.ReactNode;
  buttonStartIcon?: React.ReactNode;
  buttonColor: string;
  sx: React.ReactNode;
  actionType?: string;
  exRef: string;
}

export default function AppButton({
  buttonLabel,
  buttonType,
  isDisabled,
  buttonSize,
  onClickFunc,
  buttonStartIcon,
  buttonEndIcon,
  buttonColor,
  sx,
  actionType='button',
  exRef
}: buttonProps) {

  // console.log(exRef);
  return (
    <>
      <ThemeProvider theme={buttonTheme}>
        <Button
          ref={exRef}
          variant={buttonType}
          disabled={isDisabled}
          size={buttonSize}
          onClick={onClickFunc}
          startIcon={buttonStartIcon}
          endIcon={buttonEndIcon}
          sx={sx}
          type={actionType}
          coloro={buttonColor}
        >
          {buttonLabel}
        </Button>
      </ThemeProvider>
    </>
  );
}
