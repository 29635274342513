import React from "react"

import Grid from '@mui/material/Grid';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, FlightStepType
} from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"

import useStore from 'store/store'
import './BagType.scss'

export default function BagType({text, subText, value , itemName, typeFor, simulator, flightRecord, img}){

  const storeActions = useStore((state)=> state.actions);
  const bookingDetail = flightRecord.booking_detail;

  return (
    <Grid container spacing={2}>
      {
        !simulator && 
          <Grid item  className="">
            <div style={{width: 56, height: 90}} className="d-f jc-c" >
              <img src={img} alt={""} />
            </div>
          </Grid>
      }
      <Grid item xs className="d-f ai-c jc-sb"> 
        <Grid container spacing={0.7}> 
        <Grid item xs={12} lg={12} className={`para2 ${!simulator ? "accent2-color font-1-book": "primary1-color font-1-medium" } `}>  
          {text}
        </Grid>
        <Grid item xs={12} lg={12} className={`para3 font-1-light-bt cmn-color`}>
          {subText}
        </Grid>
        </Grid>
      </Grid>
      <Grid item className="d-f ai-c" >
          <AppButton 
              buttonLabel={ <RemoveIcon /> }
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonSize={ButtonSizeEnum.VERY_SMALL}
              buttonColor={!simulator ? (typeFor === FlightStepType.COLLECTION ? ButtonColorEnum.PRIMARY1_BG : ButtonColorEnum.WHITE_BG ): ButtonColorEnum.PRIMARY1_BG }
              onClickFunc={
                ()=> storeActions.setItemValue(flightRecord, bookingDetail[typeFor+"_"+itemName] -1, typeFor+"_"+itemName, "Bag") 
              }
            />  
            <span className={`para1 font-1-medium ${!simulator ? "accent3-color": "primary1-color" } bags-counter`}>
              { bookingDetail && bookingDetail[typeFor+"_"+itemName] }
            </span>
          <AppButton 
              buttonLabel={ <AddIcon /> }
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonSize={ButtonSizeEnum.VERY_SMALL}
              buttonColor={!simulator ? (typeFor === FlightStepType.COLLECTION ? ButtonColorEnum.PRIMARY1_BG : ButtonColorEnum.WHITE_BG ): ButtonColorEnum.PRIMARY1_BG }
              onClickFunc={
                ()=> storeActions.setItemValue(flightRecord, bookingDetail[typeFor+"_"+itemName] +1, typeFor+"_"+itemName, "Bag") 
              }
            />      
      </Grid>
    </Grid>
    )
}