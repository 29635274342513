import React from "react"
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import useStore from 'store/store'


export default function PrintChoices({sCC, index}){
  const {t} = useTranslation();

  const storeActions = useStore((state)=> state.actions);

  return (
    <Grid container className="d-f fd-r">
      <Grid item xs sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 1, paddingBottom: 2}} className={`pointer d-f fd-c ai-t  ${ sCC.printBaggageTags ? "bg-secondary2-color" : "bg-secondary1-color"}  left-slot`} onClick={()=>{
            if (sCC.bags.length>0){
              storeActions.setSelfCheckinChoices(index, "printBaggageTags", !sCC.printBaggageTags)
            }
          }}>
          <div className="font-1-light-bt para3">{t("Print")}</div>
          <div className="para1 font-1-heavy primary1-color"  >
            {t("Baggage Tags")}
          </div>
      </Grid>
      <Divider orientation="vertical" flexItem light/>
      <Grid item xs={6} sx={{paddingLeft: 2, paddingRight: 2, paddingTop: 1, paddingBottom: 2}} className={`pointer d-f fd-c ai-b ${ sCC.printBoardingPass ? "bg-secondary2-color" : "bg-secondary1-color"} right-slot`}  onClick={()=>storeActions.setSelfCheckinChoices(index, "printBoardingPass", !sCC.printBoardingPass)}>
          <div className="font-1-light-bt para3">{t("Print")}</div>
          <div className="para1 font-1-heavy primary1-color"  >
            {t("Boarding Pass")}
          </div>
      </Grid>
    </Grid>
    )
}