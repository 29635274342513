import Api from 'utils/api'
import i18n from 'utils/i18n'

export const userSlice = ((set, get) => ({
  user: null,

  actions: {

    createUser: async (params, callback) => {
      get().actions.startLoading();
      const api = new Api();
      let data = params;
      api.createUser(data)
        .then((response) => {
          try { callback(); } catch (e) {}
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },

    getUserByCode: async (params, callback) => {
      get().actions.startLoading();
      const api = new Api();
      let data = params;
      api.getUserByCode(data)
        .then((response) => {
          set((state) => ({ user: response.data.user}));
          get().actions.storeItem("user-access-token", get().user.access_token);
          try { callback(); } catch (e) {}
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },


    reloadUser: async () => {
      const api = new Api();
      api.reloadUser()
        .then((response) => {
          let user = response.data.user;
          if (!user || !user.access_token) get().actions.storeItem("user-access-token", null);
          if (user?.access_token) get().actions.storeItem("user-access-token", user.access_token);
          set((state) => ({ user: user }));
        })
      .catch((err) => console.log(err));
    },

    logout: async (callback) => {
      const api = new Api();
      api.logout()
        .then((response) => {
          get().actions.storeItem("user-access-token", null);
          set((state) => ({ user: null }));
          try { callback(); } catch (e) {}
        })
      .catch((err) => console.log(err));
    }
  }

}))