import React from "react"

import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"
import useStore from 'store/store'

export default function Initial({flightRecord}){
  const {t} = useTranslation();

  const storeActions = useStore((state)=> state.actions);

  return (
    <Grid item xs sx={{paddingTop: 1.5, paddingBottom: 1.5}} className="d-f jc-c bg-primary1-color left-slot right-slot">
        <AppButton 
          buttonLabel={t("Book Services")}
          buttonType={ButtonTypeEnum.TEXT}
          buttonSize={ButtonSizeEnum.LARGE}
          buttonColor={ButtonColorEnum.WHITE}
          buttonEndIcon={<ArrowDropDownIcon/>}
          onClickFunc={()=> storeActions.togglePanel("initialBtn", flightRecord.id, false)}
        />
    </Grid>
    )
}