import React, {Fragment, useEffect}  from "react"

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useParams} from 'react-router-dom';

import BookingContact from "components/Bookings/BookingContact/BookingContact"
import BookingPayment from "components/Bookings/BookingPayment/BookingPayment"
import BookingConfirmation from "components/Bookings/BookingConfirmation/BookingConfirmation"
import Header from "components/shared/Header/Header"
import Footer from "components/shared/Footer/Footer"

import useStore from 'store/store'
import './ManageBooking.scss'

export default function ManageBooking(){
  const booking = useStore((state)=> state.booking);
  const storeActions = useStore((state)=> state.actions);

  let { bookingCode } = useParams();

  useEffect(()=>{
    storeActions.fetchBooking({bookingCode: bookingCode});
    storeActions.fetchFlightRecords({booking_code: bookingCode});
  }, [bookingCode, storeActions]);

  return (
    <>
      <Header/>
      <div className="home-cover-bloc">
        <div className="v-space-10"></div>
        <Container>
         <Grid container>
          <Grid item xs={12}  lg={5} className="big-size-container"> 
            {
              ((booking && !booking.email) && <BookingContact />)
              ||
              ((booking && !booking.payment) && <BookingPayment />)
              ||
              ((booking && booking.payment) && <BookingConfirmation />)
            }
          </Grid>
         </Grid >
        </Container>
       
        <div className="v-space-10"></div>
      </div>
      <Footer/>
    </>
    )
}