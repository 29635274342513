import React, {useMemo} from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {useSearchParams} from 'react-router-dom';

import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";

import AppButton from "components/shared/AppButton/AppButton"
import Links from "components/shared/Links/Links"

import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";

// import {AppRoutes} from "utils/routes";
import useStore from "store/store";

import './FullNameForm.scss'

const personalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
   .required('Required'),
  last_name: Yup.string()
   .required('Required')
});

export default function ChooseAirline(){

  const {t} = useTranslation();

  let [, setSearchParams] = useSearchParams();

  const initValues = useMemo(() => ({ 
    first_name: '',
    last_name: ''
  }), []);

  const storeActions = useStore((state)=> state.actions);

  return (
    <Box className="ca-container">

      <Typography variant="span" className="h5 font-1-heavy primary1-color">
        {t("Tell us who you are:")}
      </Typography>

      <div className="v-space-6"></div>

      <Formik
        initialValues={initValues}
        validationSchema={personalInfoSchema}
        onSubmit={(values)=>{
            storeActions.setFullName(values);

            setSearchParams(params => {
              params.delete("personalInfo");
              params.set("chooseAirlinePanel", 1);
              return params;
            });
          }
        }
      >
      {({ errors, touched }) => (

        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Field  id="last_name" className="field w-100 " name="last_name" placeholder={t('Last name (as in your passport)')} />
              <div className="v-space-1"></div>
              {errors.last_name && touched.last_name ? <div className="error-color">{errors.last_name}</div> : null}
            </Grid>
            <Grid item xs={12} lg={12}>
              <Field  id="first_name" className="field w-100 " name="first_name" placeholder={t('First name (as in your passport)')} />
              <div className="v-space-1"></div>
              {errors.first_name && touched.first_name ? <div className="error-color">{errors.first_name}</div> : null}
            </Grid>
            <Grid item xs={12} lg={3}>
              <AppButton 
                sx={{width: '100%', fontSize: variables["para1-bis"]+'px', fontFamily: variables["font-1-heavy"]}}
                buttonLabel={ t("Next") }
                buttonType={ButtonTypeEnum.PRIMARY}
                buttonSize={ButtonSizeEnum.MEDIUM}
                buttonColor={ButtonColorEnum.PRIMARY_BG}
                actionType="submit" 
              />

            </Grid>
          </Grid>

        </Form>
        )}
      </Formik>

      <div className="v-space-5"></div>

      <Links login={true} register={true}/>
   
    </Box>
    )
}