import { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { FlightStepType } from "components/shared/enums/global-enums.tsx";
import direction from "assets/images/direction.png"

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
 
import useStore from 'store/store'
import './Location.scss'

const libraries = ["places"];

export default function Location({typeFor, flightRecord}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: libraries,
  });
  const {t} = useTranslation();



  return (
    <Box className={`location-container col-modifier ${typeFor}`}>
      <Grid>
        <Grid className="">
          <span className="accent2-color para1 font-1-medium">{t("Where")}</span>
          <span className="cmn-color para1 font-1-medium">{t(" would you like " + (typeFor === FlightStepType.COLLECTION ? "to check-in?" : "us to deliver to?" ))}

          </span>
        </Grid>
        <div className="v-space-2"></div>
        <Grid>
          <span className={` para3 font-1-light-bt cmn-color`}>{t("Based on the location given, we will provide possible pick up timings")}</span>
        </Grid>
        <div className="v-space-7"></div>

        <Grid>
          { isLoaded && 
            <Map typeFor={typeFor} flightRecord={flightRecord} />
          }
        </Grid>
      </Grid>
    
    </Box>

    );
}

function Map({typeFor, flightRecord}) {
  const center = useMemo(() => ({ lat: 1.290270, lng: 103.851959 }), []);
  const [selected, setSelected] = useState(center);

  return (
    <>
      <Grid>
        <div className="places-search-container">
          <PlacesAutocomplete setSelected={setSelected} typeFor={typeFor} flightRecord={flightRecord} />
          <img src={direction} alt="" className="direction pointer" />
        </div>
      </Grid>
      <div className="v-space-5"></div>

      <GoogleMap
        zoom={10}
        options={{disableDefaultUI: true}}
        center={selected}
        mapContainerClassName="map-canvas"
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </>
  );
}

const PlacesAutocomplete = ({ setSelected, typeFor, flightRecord}) => {

  const {t} = useTranslation();

  const storeActions = useStore((state)=> state.actions);

  const bookingDetail = flightRecord.booking_detail;


  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (item) => {
    setSelected(null);
    if (item === null) {
      clearSuggestions(); 
      setSelected(null);
      return;
    }

    const address = item; 
    setValue(address, false);
    clearSuggestions();

    try {
    const results = await getGeocode({ address });

    const { lat, lng } = await getLatLng(results[0]);

      setSelected({ lat, lng });
    } catch (error) {
    }

  };

  let placesList = []
  if (status === "OK"){
    placesList = [...new Set(data.map(({ place_id, description })=>{ 
      let elm = description;
      return elm;
    }))];
  }

  return (
    <>
      <Autocomplete
        freeSolo={true}
        className="autocomplete"
        onChange={(event, value) => handleSelect(value)}
        value={bookingDetail[typeFor+"_address"]}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
          storeActions.setItemValue(flightRecord, newInputValue, typeFor+"_address");
        }}
        id="combo-box-demo"
        onKeyDown={e => { if (e.key === 'Enter' && e.target.value) { handleSelect(e.target.value) } }}
        options={placesList}
        renderInput={(params) => <TextField sx={{padding: 0}} className="field" {...params} label={t("Pickup Postal code or Hotel name")}
          
         />}
        />
        <div className="v-space-2"></div>
        <input className="field w-100 para2" onChange={(e) => { storeActions.setItemValue(flightRecord, e.target.value, typeFor+"_room_number")}} placeholder={t('Room/Unit Number')}  value={bookingDetail[typeFor+"_room_number"]||''}/>
    </>
  );
};