import React, {useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import Grid from '@mui/material/Grid';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";

import AppButton from "components/shared/AppButton/AppButton"

import {AppRoutes} from "utils/routes";
import useStore from "store/store";
import './AccessForm.scss'


export default function AccessForm({title, btnTitle, validationSchema, initValues , inputs, action, type}){
  
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState(false);

  const user = useStore((state)=> state.user);

  useEffect(()=>{
    if (successMessage && user?.id){
      
      navigate(AppRoutes.indexPath);
    }
  }, [user, successMessage, navigate])

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <div className="para2 font-1-heavy">
          {
            successMessage ? t("Thank you. Your information has been saved. "): (
              title
              )
          }
        </div>
      </Grid>

      <Grid item xs={12} md={6}>
        {
        !successMessage &&
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values)=>{
              values.type = type;
              action(values, ()=>setSuccessMessage(true)); 
              }
            }
          >
          {({ errors, touched }) => (
           <Form>
              {
                inputs.map((input, key)=>{
                  return (<div className="w-100 " key={key}>
                      <Field  id={input.name} className="field w-100 " name={input.name} placeholder={input.placeholder} />
                      <div className="v-space-1"></div>
                      {errors[input.name] && touched[input.name] ? <div className="error-color">{errors[input.name]}</div> : null}
                      <div className="v-space-4"></div>
                    </div>)
                })
              }
              <AppButton 
                sx={{paddingLeft: 8, paddingRight: 8}}
                buttonLabel={ btnTitle }
                actionType="submit"
                buttonType={ButtonTypeEnum.PRIMARY}
                buttonSize={ButtonSizeEnum.SMALL}
                buttonColor={ButtonColorEnum.BLACK_BG_WHITE}
              />
            </Form>
            )}
        </Formik>
        
        }
      </Grid>
    </Grid>
  
    )
}
