import React, { useEffect, useRef, useState }  from "react"
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useSearchParams} from 'react-router-dom';

import Header from "components/shared/Header/Header"
import SwitchMenu from "components/Home/SwitchMenu/SwitchMenu"
import ChooseAirline from "components/Home/ChooseAirline/ChooseAirline"
import FullNameForm from "components/Home/FullNameForm/FullNameForm"
import ServiceSummary from "components/Home/ServiceSummary/ServiceSummary"
import CheckPrices from "components/Home/CheckPrices/CheckPrices"
import Footer from "components/shared/Footer/Footer"
// import landingVideo from 'assets/videos/landing.mp4'
import logo from "assets/images/logo.png"
// import home2 from "assets/images/home2.webp"
// import home3 from "assets/images/home3.webp"
// import home4 from "assets/images/home4.webp"



import { useTranslation } from 'react-i18next';
import {
  HomeSwitchMenu
} from "components/shared/enums/global-enums.tsx";

import './Home.scss'

export default function Home({landingVideo}){
  const {t} = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();
  let activeTab = searchParams.get("activeTab");
  let chooseAirlinePanel = searchParams.get("chooseAirlinePanel");
  let personalInfo = searchParams.get("personalInfo");
  const [featuresBox, setFeaturesBox] = useState(false);


  const handleSwitchMenu = (tab) => {
    setSearchParams(params => {
      params.set("activeTab", tab);
      return params;
    });
  };


  const location = useLocation();
  const lastHash = useRef('');
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);


  useEffect(()=>{
    setTimeout(()=>{
      setFeaturesBox(true);
    }, 2000)
  }, []);
    

  return (
    <div className="top-container">
      <Header/>
      <div className="home-cover-bloc">

        <video src={landingVideo} playsInline autoPlay muted loop className="video-bg" />

          
        <div className="v-space-10"></div>
        {
          featuresBox &&
          <Container>
           <Grid container className="features-box">
            <Grid item xs={12} md={12} lg={7}> 
              <SwitchMenu activeTab={activeTab} handleSwitchMenu={handleSwitchMenu} />
              { 
                (!activeTab || activeTab === HomeSwitchMenu.BOOK_SERVICE) && 
                (
                  ((!chooseAirlinePanel && !personalInfo) && <ServiceSummary  />)
                  ||
                  (
                    personalInfo && <FullNameForm  />
                  )
                  ||
                  (
                    chooseAirlinePanel && <ChooseAirline  />
                  )
                )
              }
              { 
                activeTab === HomeSwitchMenu.CHECK_PRICES &&
                <CheckPrices />
              }
              
            </Grid>
           </Grid >
          </Container>
        }
        <div className="v-space-10"></div>
        <div className="v-space-10"></div>
        <div className="floating-logo">
          <img className="img" src={logo} alt=""/>
          <div className="font-1-medium para1 uppercase white-color">PORTAL</div>
        </div>
        <div className="v-space-10"></div>
      </div>
      
      <div className="v-space-10"></div>

      <div id="how_it_works" className="h-big-title c-txt font-1-heavy h3  black-color " >
        {t("HOW DOES PORTAL WORK?")}
      </div>


      <div className="home-2-bloc">
        <Container maxWidth="xl">
          <Grid container >
              <Grid item xs={12} md={12} lg > 
                <div className="txt-part">
                  <div className="font-1-heavy h3  primary1-color">{t("1. Book your pick-up")}</div>
                  <div className="v-space-10"></div>
                  <div className="font-1-heavy h5  white-color">{t("Choose a time and place for your check-in and we’ll be there.")}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg className="img-part"> 
                <div className="img "></div>
              </Grid>
          </Grid>
        </Container>
      </div>

      <div className="home-3-bloc">
        <Container maxWidth="xl">
          <Grid container >
              <Grid item xs={12} md={12} lg className="h-txt-mobile "> 
                <div  className="txt-part ">
                  <div className="mb-a"></div>
                    <div className="font-1-heavy h3  primary1-color">{t("2. Check In")}</div>
                    <div className="v-space-10"></div>
                    <div className="font-1-heavy h5  white-color">{t("One of our professional handling agents will check you in for your flight and collect your luggage.")}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg className="img-part"> 
                <div className="img "></div>
              </Grid>
              <Grid item xs={12} md={12} lg className="h-txt-desktop  ai-c"> 
                <div  className="txt-part ">
                  <div className="mb-a"></div>
                    <div className="font-1-heavy h3  primary1-color">{t("2. Check In")}</div>
                    <div className="v-space-10"></div>
                    <div className="font-1-heavy h5  white-color">{t("One of our professional handling agents will check you in for your flight and collect your luggage.")}</div>
                </div>
              </Grid>
          </Grid>
        </Container>
      </div>

      <div className="home-4-bloc">
        <Container maxWidth="xl">
          <Grid container >
              <Grid item xs={12} md={12} lg className="d-f ai-c "> 
                <div className="txt-part">
                  <div className="font-1-heavy h3  primary1-color">{t("3. Be Free")}</div>
                  <div className="v-space-10"></div>
                  <div className="font-1-heavy h5  white-color">{t("Do as you please and head straight to the gate once you reach the airport. You’ll reunite with your bags at the destination.")}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg className="img-part"> 
                <div className="img "></div>
              </Grid>
          </Grid>
        </Container>
      </div>


      <div className="h-big-title ft-note c-el c-txt font-1-heavy h5  white-color ">
        {t("Or have your bags sent straight to your hotel/home after arrival and skip waiting at the carousel.")}
      </div>

      <div className="v-space-10"></div>
      <div className="v-space-10"></div>

      <Footer/>
    </div>
    )
}