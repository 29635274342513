import React, {useState} from "react"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {FlightStepType} from "components/shared/enums/global-enums.tsx";
import { useTranslation } from 'react-i18next';
import useStore from 'store/store'
import {shortDateOnly, timeOnly} from 'utils/helperFunctions'

import './DateTime.scss'

export default function DateTime({typeFor, flightRecord}){
  const {t} = useTranslation();
  const bookingDetail = flightRecord.booking_detail;
  const config = useStore((state)=> state.config);
  const [cDT, setCDT] = useState(new Date(bookingDetail[typeFor+"_at"]));

  let minClDate, maxClDate, verb;
  if (typeFor === FlightStepType.COLLECTION){
    verb = "check-in";
    minClDate = new Date(flightRecord.modified_departure); minClDate.setHours(minClDate.getHours() - parseInt(config.min_collection));
    maxClDate = new Date(flightRecord.modified_departure); maxClDate.setHours(maxClDate.getHours() - parseInt(config.max_collection));
  }else{
    verb = "deliver";
    minClDate = new Date(flightRecord.modified_arrival); minClDate.setHours(minClDate.getHours() + parseInt(config.min_delivery));
    maxClDate = new Date(flightRecord.modified_arrival); maxClDate.setHours(maxClDate.getHours() + parseInt(config.max_delivery));
  }
  // Get dates
  let days = [];
  let loopDays = new Date(minClDate);
  while (loopDays <= maxClDate) {
    days.push(shortDateOnly(loopDays));
    loopDays.setHours(loopDays.getHours() + 24);
  }
  days.push(shortDateOnly(maxClDate));
  days = [...new Set(days)];


  // Get times
  let dateTimes = [];
  let loopDateTimes = new Date(minClDate);
  while (loopDateTimes <= maxClDate) {
    dateTimes.push(new Date(loopDateTimes));
    loopDateTimes.setMinutes(loopDateTimes.getMinutes() + 30);
  }
  dateTimes = [...new Set(dateTimes)];

  return (
    <>
      <Box className={`dt-container col-modifier ${typeFor}`} >
        <Grid>
          <Grid className="">
            <span className="accent2-color para1 font-1-medium">{t("When")}</span>
            <span className="cmn-color para1 font-1-medium">{t(` would you like to ${verb}?`)}</span>
          </Grid>
          <div className="v-space-2"></div>
          <Grid>
            <span className={`para3 font-1-light-bt cmn-color`}>{
              (typeFor===FlightStepType.COLLECTION) ? t("Timings within 4 hours before departure time will incur additional express charges") : t("Timings within 2 hours from arrival time will incur additional express charges") }</span>
          </Grid>
          <div className="v-space-7"></div>
          <DatesList flightRecord={flightRecord} currentDate={cDT}  typeFor={typeFor} setCDT={setCDT} days={days}  dateTimes={dateTimes}/>
          <div className="v-space-7"></div>
          <TimesComponent flightRecord={flightRecord} currentDate={cDT}  typeFor={typeFor}  dateTimes={dateTimes}/>
          <div className="v-space-5"></div>
        </Grid>
      </Box>

    </>
    )
}


const DatesList = ({currentDate, typeFor, days, dateTimes, setCDT, flightRecord}) => {
  const storeActions = useStore((state)=> state.actions);

  function selectDate(date) {
    for (var i = 0; i < dateTimes.length; i++) {
      if (date === shortDateOnly(dateTimes[i])) {
        setCDT(dateTimes[i]);
        return storeActions.setItemValue(flightRecord, dateTimes[i], typeFor+"_at", "Date");
      }
    }
  }

  return (
    <Grid className="d-f fd-r jc-sa">
      {days.map((day, key)=>{
          let activeClass = "";
          if (shortDateOnly(currentDate) === day) activeClass = "active";
          return (
            <div key={key}  className={`circle ${activeClass}`}  onClick={()=>{selectDate(day);}}>
              {day}
            </div>
          )
        })}
    </Grid>
    )
}


const TimesComponent = ({currentDate, typeFor, dateTimes, flightRecord}) => {
  const storeActions = useStore((state)=> state.actions);
  return (
      <Grid className="d-f fd-r jc-sa">
        <select defaultValue={currentDate} className="custom-time-picker w-100 c-txt" onChange={(e)=>storeActions.setItemValue(flightRecord, e.target.value, typeFor+"_at", "Date")}>
          {dateTimes.map((dateTime, key)=>{
            let hour1 = timeOnly(dateTime);
            let hour2 = new Date(dateTime);
            hour2 = timeOnly(new Date(hour2.setMinutes(hour2.getMinutes()+30)));
            if (shortDateOnly(currentDate) !== shortDateOnly(dateTime)) return null;
            return (<option key={key} value={dateTime}>{hour1+' - '+hour2}</option>)
          })}  
        </select>

      </Grid>
    )
}