import {produce} from 'immer'
export const utilsSlice = ((set, get) => ({
  frFirstName: '',
  frLastName: '',

  bagsPanel: {},
  locationPanel: {},
  dateTimePanel: {},
  passengersPanel: {},
  initialBtn: {},

  selfCheckinMode: false,

  activeStepPanel: {},

  loadingProcesses: 0,

  snackMessageShown: false,
  snackMessageType: null,
  snackMessage: null,

  timoutId: null,

  actions: {
    clearExTimeOut: ()=>{
      let id = get().timeoutId;
      if (id) {
        clearTimeout(id);
        set((state) => ({ timeoutId: null}));
      }
    },

    startTimeout: (callbackFunc, timeOut)=>{
      get().actions.clearExTimeOut();
      const tmId = setTimeout(()=> {
        callbackFunc();
      }, timeOut);
      set((state) => ({ timeoutId: tmId}));
    },

    setSelfCheckinMode: (val)=>{
      set((state) => ({ selfCheckinMode: val}));
    },

    preparePanels: ()=>{
      let flightRecords = get().flightRecords;
      let bagsPanel={}, locationPanel={}, dateTimePanel={}, passengersPanel={}, activeStepPanel={}, initialBtn={};

      for (const flightRecord of flightRecords){
        bagsPanel[flightRecord.id] = false;
        locationPanel[flightRecord.id] = false;
        dateTimePanel[flightRecord.id] = false;
        passengersPanel[flightRecord.id] = false;
        activeStepPanel[flightRecord.id] = '';
        initialBtn[flightRecord.id] = true;
      }

      set((state) => ({ bagsPanel: bagsPanel, locationPanel: locationPanel, dateTimePanel: dateTimePanel, passengersPanel: passengersPanel, activeStepPanel: activeStepPanel, initialBtn: initialBtn  }));
    },

    startLoading: ()=>{
      let loadingCpt = get().loadingProcesses+1;
      set((state) => ({ loadingProcesses: loadingCpt}));
    },

    stopLoading: ()=>{
      let loadingCpt = get().loadingProcesses-1;
      if (loadingCpt < 0) loadingCpt=0;
      set((state) => ({ loadingProcesses: loadingCpt}));
    },

    togglePanel:(name, id, val)  => {
      if (val === undefined) val = !get()[name][id];
      set(produce((state) => { 
          state[name][id] = val;
      }));
    },

    toggleFlightStepPanel:(id, stepPanel)  => {
      set(produce((state) => { 
          state.activeStepPanel[id] = stepPanel;
      }));
    },

    setFullName: (params) => {
      set((state) => ({ frFirstName: params.first_name, frLastName: params.last_name}));
    },

    setSnackMessage: (params) =>{
      let newState = {
          snackMessageShown: params.show, 
          snackMessageType: params.type,
          snackMessage: params.text
      };
      
      set((state) => (newState));
    },
  }

}))