import React  from "react"
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, FlightStepType } from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"
import TicketDelimiter from "components/shared/TicketDelimiter/TicketDelimiter"
import {dateOnly, timeOnly, exactAirport} from 'utils/helperFunctions'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {AppRoutes} from "utils/routes";

import useStore from 'store/store'
import './OrderSummary.scss'

export default function OrderSummary({displayType}){
  const {t} = useTranslation();
  const navigate = useNavigate();
  const booking = useStore((state)=> state.booking);
  let flightRecords = useStore((state)=> state.flightRecords);
  flightRecords = flightRecords.filter(flightRecord => flightRecord.booking_detail?.completed_one_step);

  function handleEdit(){
    let prms = {airline: booking.airline, first_name: booking.first_name, last_name: booking.last_name, pnr: booking.pnr, booking_code: booking.code}
    let prmStr = new URLSearchParams(prms).toString();
    navigate(AppRoutes.flightsPath+"?"+prmStr);
  }

  return (
    <Grid  className="order-sum-container">
      <div className="header">
        <div className="h6 black-color font-1-heavy">
          {t("Order "+displayType)}
        </div>
        <div className="v-space-3"></div> 
      </div>
      {
        flightRecords.map((flightRecord, key)=>{

          const bookingServices = [];
          const bookingDetail = flightRecord.booking_detail;
          if (bookingDetail?.completed_collection) bookingServices.push(FlightStepType.COLLECTION);
          if (bookingDetail?.completed_delivery) bookingServices.push(FlightStepType.DELIVERY);

          return(
              <div key={key}>

              {bookingServices.map((bookingService, key2)=>{
                let date = new Date(bookingDetail[bookingService+"_at"])
                let date2 = new Date(date);
                date2.setMinutes(date2.getMinutes()+30);

                return(
                  <div key={key2}>
                  
                    {(key !== 0) && (key2 === 0) && 
                      <TicketDelimiter theme={bookingService} halfPart="bottom"/>
                    }
                    {
                      (key2 === 0) &&
                      <div className={`para2 sub-header  bg-${bookingServices[0]} font-1-light-bt white-color`}>
                      {flightRecord.airline} {" Airlines "} {flightRecord.flight_number} {flightRecord.departure_airport.code}{" to "}{exactAirport(FlightStepType.DELIVERY, flightRecord).code}
                      </div>
                    }

                  <Grid   className={`bg-color-${bookingService}  ${((key === flightRecords.length-1) && (key2 === bookingServices.length-1) ? 'bottom-radius' : '')}`}>
                    <Grid container >
                      <Grid item xs={6} className="para1 secondary2-color font-1-medium cap">
                        {t("Items")}
                      </Grid>
                      <Grid item xs={6} className="para1 secondary2-color font-1-medium cap r-txt">
                        {t(bookingService)}
                      </Grid>
                    </Grid>
                    
                    <div className="v-space-2"></div>
                    <Grid container spacing={2}>
                      <Grid container spacing={1} item xs={4}>
                        <Grid item xs={12} className="d-f jc-sb para2 white-color font-1-book">
                          <div className=""> {t("Bags")} </div>
                          <div className=""> {bookingDetail[bookingService+"_bags"]} </div>
                        </Grid>
                        <Grid  item xs={12}  className="d-f jc-sb para2 white-color font-1-book">
                          <div className=""> {t("Child carriers")} </div>
                          <div className=""> {bookingDetail[bookingService+"_child_carriers"]} </div>
                        </Grid>
                        <Grid  item xs={12}  className="d-f jc-sb para2 white-color font-1-book">
                          <div className=""> {t("Odd-Sized")} </div>
                          <div className=""> {bookingDetail[bookingService+"_odd_sized_luggages"]} </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} item xs={8}>
                        <Grid item xs={12} className="d-f fd-c jc-r para2 white-color font-1-book cap r-txt">
                          {bookingDetail[bookingService+"_address"]}
                          <div className="v-space-1"></div>
                          <div className="d-f jc-r para3 white-color font-1-light-bt cap r-txt">
                            {bookingDetail[bookingService+"_room_number"]? t("room/unit "): ""}{bookingDetail[bookingService+"_room_number"]}

                          </div>
                        </Grid>
                       
                        <Grid  item xs={12}  className="d-f fd-c jc-sb para2 white-color font-1-book">
                          <div className="para3 font-1-book white-color d-f jc-r r-txt">{dateOnly(date)}</div>
                          <div className="para3 font-1-book white-color d-f jc-r r-txt">{timeOnly(date)+' - '+timeOnly(date2)}</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    {
                      (key === flightRecords.length-1) && (key2 === bookingServices.length-1) &&
                        <>
                        <div className="v-space-2"></div>
                        <Grid container >
                          <Grid item xs={6} className="">
                            { displayType === t('summery') && 
                              <AppButton 
                                sx={{paddingLeft: 0}}
                                buttonStartIcon={<ArrowLeftIcon />}
                                buttonLabel={ t("Edit") }
                                buttonType={ButtonTypeEnum.TEXT}
                                buttonSize={ButtonSizeEnum.MEDIUM}
                                buttonColor={ButtonColorEnum.SECONDARY2}
                                onClickFunc={handleEdit}
                              />
                            }
                          </Grid>
                          <Grid  item xs={6}  className="d-f ai-c jc-r para1 white-color font-1-heavy r-txt">
                            ${booking.payment? parseInt(booking.payment.amount) : booking.eprice}
                          </Grid>
                        </Grid>
                        </>
                    }
                  </Grid>
                  {(key !== flightRecords.length-1) && (key2 === bookingServices.length-1) && <TicketDelimiter theme={bookingService} halfPart="top"/>}
                  </div>
                  )
                })}
              </div>
            )
        })


      }
    </Grid>
    )
}