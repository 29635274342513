export const AppRoutes = {
  indexPath: "/",
  landingPath: "/Landing",
  flightsPath: "/flights",
  selfCheckinPath: "/self_checkin",
  manageFlightPath: "/flights/:flightRecordId",
  manageBookingPath: "/bookings/:bookingCode",
  pagePath: "/page",
  scaleTestPath: "/scale_test"

}