import Api from 'utils/api'
import i18n from 'utils/i18n'

export const paymentSlice = ((set, get) => ({
  payment: null,

  actions: {

    placeOrder: async (params) => {
      get().actions.startLoading();
      const api = new Api();
      let data = params;
      api.placeOrder(data)
        .then((response) => {
          data.booking.payment = response.data.payment;
          set((state) => ({ booking: data.booking }));
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },

  }

}))