import React from "react"

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import TicketDelimiter from "components/shared/TicketDelimiter/TicketDelimiter"

import useStore from 'store/store'
import './Passenger.scss'

export default function Passenger({flightRecord}){

  const {t} = useTranslation();

  const passengersPanel = useStore((state)=> state.passengersPanel);

  const selfCheckinMode = useStore((state)=> state.selfCheckinMode);
  const storeActions = useStore((state)=> state.actions);

  return (
    <div className="p-relative">
      <TicketDelimiter theme={selfCheckinMode ? "dark" :  ""}/>
      <div className="collapsable" onClick={()=>storeActions.togglePanel("passengersPanel", flightRecord.id)}>{passengersPanel[flightRecord.id]? t("less"):t("more")}{" "}{t("details")}</div>

      {(selfCheckinMode || passengersPanel[flightRecord.id]) &&
        <>
          <Box className={`passenger-container ${selfCheckinMode ? "less-padds" :  ""}`}>
           <Grid container spacing={selfCheckinMode? 0.7 : 2}>
            <Grid item xs={12} container >
              <Grid item xs={6} container spacing={1}>
                <Grid item xs={12} className="para3 font-1-light-bt black-color-opac">{t("Passenger")}</Grid>
                <Grid item xs={12} className="primary1-color font-1-heavy para1">{flightRecord.first_name+" "+flightRecord.last_name}</Grid>
              </Grid>
              <Grid item xs={6} container spacing={1}>
                <Grid item xs={12} className="r-txt para3 font-1-light-bt black-color-opac">{t("Passengers in Group")}</Grid>
                <Grid item xs={12} className="r-txt primary1-color font-1-heavy para1">{flightRecord.passengers_in_group}</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={4} container spacing={1}>
                <Grid item xs={12} className="para3 font-1-light-bt black-color-opac">{t("Gate")}</Grid>
                <Grid item xs={12} className="primary1-color font-1-heavy para1">{flightRecord.gate}</Grid>
                
              </Grid>
              <Grid item xs={4} container spacing={1}>
                <Grid item xs={12} className="para3 font-1-light-bt black-color-opac c-txt">{t("Boarding")}</Grid>
                <Grid item xs={12} className="primary1-color font-1-heavy para1 c-txt">{flightRecord.boarding}</Grid>
                
              </Grid>
              <Grid item xs={4} container spacing={1}>
                <Grid item xs={12} className="r-txt para3 font-1-light-bt black-color-opac">{t("Seat")}</Grid>
                <Grid item xs={12} className="r-txt primary1-color font-1-heavy para1">{flightRecord.seat}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        
        <TicketDelimiter theme={selfCheckinMode ? "dark" :  ""}/>
        { !selfCheckinMode && <div className="collapsable" onClick={()=>storeActions.togglePanel("passengersPanel", flightRecord.id)}>{passengersPanel[flightRecord.id]? t("less"):t("more")}{" "}{t("details")}</div>}
        </>
      }
    </div>
    )
}