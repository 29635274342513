export const enum ButtonTypeEnum {
  TEXT = "text",
  PRIMARY = "primary",
  OUTLINE = "outline"
}

export const enum ButtonSizeEnum {
  VERY_SMALL = "very-small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const enum ButtonColorEnum {
  WHITE_BG = "white-bg",
  SECONDARY1_BG = "secondary1-bg",
  SECONDARY2_BG = "secondary2-bg",
  PRIMARY2_BG = "primary2-bg",
  PRIMARY3_BG = "primary3-bg",
  SECONDARY2_BG_WHITE = "secondary2-bg-white",
  SECONDARY2 = "secondary2",
  PRIMARY3 = "primary3",
  PRIMARY1 = "primary1",
  BLACK = "black",
  WHITE = "white",
  WHITE_BG_SECONDARY2 = "white-bg-secondary2",
  WHITE_BG_BLACK = "white-bg-black",
  BLACK_BG_WHITE = "black-bg-white"
}


export const enum HomeSwitchMenu {
  BOOK_SERVICE = "BookService",  
  CHECK_PRICES = "CheckPrices"  
}


export const enum FlightStepType {
  COLLECTION = "collection",  
  DELIVERY = "delivery"
}


export const enum BagsType {
  BAGS = "bags",  
  CHILD_CARRIERS = "child_carriers" , 
  ODD_SIZED_LAGGAGES = "odd_sized_luggages"  
}


export const enum DateTimeFormat { 
  DATE = { day:"numeric",  year:"numeric", month:"long"},
  MEDIUM_DATE = { day:"numeric",  month:"short"},
  SHORT_DATE = { day:"numeric", weekday: "short"},
  TIME = {hour:"numeric", minute:"2-digit"},
  TIME_24 = {hour12: false, hour:"numeric", minute:"2-digit"}
}

export const enum ConfigEnum { 
  COLLECTION = "collection",
  DELIVERY = "delivery",
  BAGS_LIMIT = "bags_limit",
  PRICES = "prices"
}

export const enum SelfCheckinStepEnum {
  IDLE = "0",
  AIRLINE_SELECTION = "1",
  SCAN_PASSPORT = "2",
  PASSENGER = "3",
  DANGEROUS_GOODS = "4",
  BAGS_SCALE = "5",
  ITEMS_TO_PRINT = "6",
  PRINTING = "7",
  THANKS = "8"
}

export const enum SelfCheckinActiveBtnEnum { 
  INITIAL = "initial",
  BAGS_COUNT = "bags-count",
  PRINTING = "printing"
}

export const enum TypeReadBus { 
  SCAN = "scan",
  PRINT = "print"
}


export const enum AudienceTypes { 
  PARTNER = "PARTNER",
  TRAVELLER = "TRAVELLER"
}
