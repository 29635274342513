import React, {useEffect, useState}  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import dangerousgoods from "assets/images/dangerousgoods.png"
import bags from "assets/images/bags.png"
import bagWhite from "assets/images/bagWhite.png"
import { useTranslation } from 'react-i18next';
import MessageScreen from "components/SelfCheckin/MessageScreen/MessageScreen"
import { SelfCheckinStepEnum } from "components/shared/enums/global-enums.tsx";
import useStore from 'store/store'

import './ConfirmationMessage.scss'

export default function ConfirmationMessage({typeFor, forceFooter}){
  const {t} = useTranslation();
  const [redirect, ] = useSelfCheckinNafigation();
  const selfCheckinChoices = useStore((state)=> state.selfCheckinChoices);
  const storeActions = useStore((state)=> state.actions);

  const [imgToTop, setImgToTop] = useState(true);
  const [message, setMessage] = useState(null);
  const [subMessage, setSubMessage] = useState(null);
  const [img, setImg] = useState(null);

  useEffect(()=>{
    if (!selfCheckinChoices.find(el => el.confirmed)){
      return redirect(-1); 
    }

    if (typeFor === SelfCheckinStepEnum.PRINTING){
      setMessage(t("NO DOCUMENTS SELECTED"));
      setSubMessage(t("Please confirm that you do not wish to print in any documents."));
      setImg(bagWhite);
    }else if (storeActions.checkinChoicesBags().length===0){
      setMessage(t("NO BAGGAGE SELECTED"));
      setSubMessage(t("Please confirm that you do not wish to check in any bags."));
      setImg(bags);
    }else{
      setMessage(t("Dangerous Goods"));
      setSubMessage(t("not allowed in the cabin or as checked baggage"));
      setImg(dangerousgoods);
      setImgToTop(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, storeActions, typeFor]);

  return (
    <MessageScreen message={message} subMessage={subMessage} mimg={img} imgToTop={imgToTop} 

      forwordAddFunc={()=>{
        if (typeFor === SelfCheckinStepEnum.PRINTING){
          redirect(1); return; 
        }
        if (storeActions.checkinChoicesBags().length===0){
          redirect(-1, SelfCheckinStepEnum.ITEMS_TO_PRINT) 
          return;         
        }
      }}
      forwordTitle="Confirm"
      forceFooter={forceFooter}

        />
    )
}