import React from "react"

import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import { useTranslation } from 'react-i18next';
import {exactAirport} from 'utils/helperFunctions'
import AppButton from "components/shared/AppButton/AppButton"
import useStore from 'store/store'

export default function Start({flightRecord, typeFor}){

  const {t} = useTranslation();

  const storeActions = useStore((state)=> state.actions);

  const airport = exactAirport(typeFor, flightRecord);

  return (
      <Grid item className="d-f jc-c fd-c">
        <div className="para3 white-color c-txt w-100 font-1-light-bt">{(airport[typeFor+"_status"] === 1) ? airport.city : t("Not available") } </div>
        <AppButton 
          buttonLabel={ t("check_"+typeFor) }
          buttonType={ButtonTypeEnum.TEXT}
          buttonSize={ButtonSizeEnum.MEDIUM}
          buttonColor={ButtonColorEnum.WHITE}
          buttonEndIcon={<ArrowDropDownIcon/>}
          onClickFunc={()=>{
              if (airport[typeFor+"_status"] !== 1) return;
              storeActions.togglePanel("bagsPanel", flightRecord.id, true) ;
              storeActions.toggleFlightStepPanel(flightRecord.id, typeFor)
            }
          }
        />
      </Grid>
    )
}