import React, {useEffect, useState}  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import { useTranslation } from 'react-i18next';
import MessageScreen from "components/SelfCheckin/MessageScreen/MessageScreen"
import { SelfCheckinStepEnum } from "components/shared/enums/global-enums.tsx";
import useStore from 'store/store'

import './BagsScale.scss'

export default function BagsScale(){
  const {t} = useTranslation();

  const [redirect, ] = useSelfCheckinNafigation();
  const storeActions = useStore((state)=> state.actions);
  const [message, setMessage] = useState(null);
  const [subMessage, setSubMessage] = useState(null);
  const [cBags, setCBags] = useState(0);
  const cCB = storeActions.checkinChoicesBags();

  const socket = new WebSocket("ws://127.0.0.1:1880/weight");

  useEffect(()=>{

    if (cCB.length===0){
      redirect(0, SelfCheckinStepEnum.ITEMS_TO_PRINT);
    }

    async function readBagWeight () {
      // Connection opened
      socket.addEventListener("open", event => {
        socket.send("COX");
      });

      socket.addEventListener("error", event => {
        setSubMessage(t("Load cell not connected!"));
      });

      // Listen for messages
      socket.addEventListener("message", event => {
        let wt = event.data;
        wt = wt.substring(wt.indexOf(",0") + 2);
        wt = wt.substring(0, wt.length-4);
        wt = wt.replace(" ", "");
        wt = parseFloat(wt)/100;
        if (subMessage !== wt){
          setSubMessage(String(wt)+"kg");
        }else{
          setSubMessage("- -");
        }
      });
    }

    readBagWeight();
    setMessage(t("PLACE FIRST BAG ON CART OF "+cCB[0].name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessageScreen message={message} subMessage={subMessage}  
      forwordAddFunc={()=>{
        storeActions.startTimeout(()=>{redirect(-1, SelfCheckinStepEnum.IDLE)}, 60000);
          if (cBags >= cCB.length-1) {
            try { socket.close(); } catch(e) {}
            redirect(1); 
            return
          }
          let v = cBags; v++;
          setMessage(t("PLACE "+cCB[v].order+" BAG ON CART OF "+cCB[v].name));
          setCBags(v);
          } 
        }
      forwordStep={0}

      backAddFunc={()=>{
        storeActions.startTimeout(()=>{redirect(-1, SelfCheckinStepEnum.IDLE)}, 60000);
          if (cBags <= 0) {redirect(-1); return};
          let v = cBags; v--;
          setMessage(t("PLACE "+cCB[v].order+" BAG ON CART OF "+cCB[v].name));
          setCBags(v);
          } 
        }
      backStep={0}

        />
  
    )
}