import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "utils/routes";
import useStore from 'store/store'

export default function ProtectedRoute({elm}) {
  const navigate = useNavigate();
  const storeActions = useStore((state)=> state.actions);

  const accessToken = storeActions.retrieveItem("user-access-token");

  useEffect(() =>{
    if (!accessToken) {
      navigate(AppRoutes.landingPath);
    }
  }, [accessToken, navigate]);


  return (
      <>
          {
            (accessToken) ? elm : null
          }
      </>
  );
}
