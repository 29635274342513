export const cachingSlice = ((set, get) => ({

  actions: {
     storeItem: (key, elm)=>{
       let item = JSON.stringify(elm);
       localStorage.setItem('singaportal-'+key, item);
     },
     retrieveItem: (key)=>{
       let item = localStorage.getItem('singaportal-'+key);
       return JSON.parse(item);
     }
  }

}))