import Api from 'utils/api'
import i18n from 'utils/i18n'

export const configsSlice = ((set, get) => ({
  config: {},

  actions: {
    fetchConfigs: async (params) => {
      get().actions.startLoading();
      const api = new Api();
      api.getConfigs(params)
        .then((response) => {
          set((state) => ({ config: response.data.config }));
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    }
  }

}))