import React  from "react"

import Grid from '@mui/material/Grid';

import OrderSummary from "components/Bookings/OrderSummary/OrderSummary"

import { useTranslation } from 'react-i18next';
import useStore from 'store/store'

import './BookingConfirmation.scss'


export default function BookingConfirmation(){
  const {t} = useTranslation();

  const booking = useStore((state)=> state.booking);

  return (
    <Grid  className="bk-payment-container" >


      <OrderSummary displayType={t('confirmation')}/>
      <div className="v-space-1"></div>
      
      <Grid  className="bk-contact-container" >
        <Grid className="primary1-color para1 font-1-heavy c-txt">
          {t("Thank you for your booking!")}
        </Grid>
        <div className="v-space-6"></div>
        <Grid className="primary1-color para2 font-1-book c-txt">
          {t("Your booking code is:")}
        </Grid>
        <Grid className="black-color para1 font-1-medium c-txt">
          {booking.code}
        </Grid>
        <div className="v-space-6"></div>
        <Grid className="primary1-color para2 font-1-book c-txt">
          {t("We have sent an email confirmation to: "+booking.email)}
        </Grid>
        <div className="v-space-6"></div>
        <Grid className="primary1-color para2 font-1-book c-txt">
          {t("Use the above code and your email address to manage and edit your booking anytime on our website. ")}
        </Grid>
      </Grid>


      <div className="v-space-5"></div>

      

    </Grid>
    )
}