import React from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Airport from "./Airport/Airport"

import airplane from 'assets/images/airplane.png'
import useStore from 'store/store'
import './FlightDetail.scss'

export default function FlightDetail({flightRecord}){

  const storeActions = useStore((state)=> state.actions);
  const selfCheckinMode = useStore((state)=> state.selfCheckinMode);

  return (
    <>
      <Box className={`flight-container ${selfCheckinMode ? "less-padds" :  ""}`} onClick={()=>storeActions.togglePanel("passengersPanel", flightRecord.id, false)}>
        <Grid container>
          <Grid item xs={5} container >
            <Airport airport={flightRecord.departure_airport} terminal={flightRecord.departure_terminal} time={flightRecord.departure_at} direction="left"/>
          </Grid>
          <Grid item xs={2} className="d-f ai-c jc-c">
            <img src={airplane} alt="" />
          </Grid>
          <Grid item xs={5} container>
            <Airport airport={flightRecord.arrival_airport} terminal={flightRecord.arrival_terminal} time={flightRecord.arrival_at} direction="right"/>
          </Grid>
        </Grid>

     
      </Box>

    </>
    )
}