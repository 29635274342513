import {useState} from 'react';
import {Link} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import logo from 'assets/images/logo.png'

import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import {AppRoutes} from "utils/routes";
import AppButton from "components/shared/AppButton/AppButton"

import { useTranslation } from 'react-i18next';

import './Header.scss';


function ResponsiveAppBar() {
  
  const {t} = useTranslation();
  
  const pages = [
    { name:t('Manage Booking'), url: AppRoutes.indexPath+"?activeTab=BookService#how_it_works"}, 
    { name:t('Services'), url: AppRoutes.indexPath+"?activeTab=BookService"}, 
    { name:t('Pricing'), url: AppRoutes.indexPath+"?activeTab=CheckPrices"}, 
    { name: t('Help Desk'), url: AppRoutes.pagePath}
  ];

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="relative" className="bg-black-color" sx={{zIndex: 1000}}>
      <Container >
        <Toolbar sx={{height: 72}}  disableGutters>
          
          <a href="/"><img src={logo} alt="logo" className="logo" /></a>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon className="white-color"/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, key) => (
                <MenuItem key={key} onClick={handleCloseNavMenu}>
                  <Link to={page.url} >
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
         
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, key) => (
              <Link to={page.url} key={key} >
                <AppButton 
                  sx={{marginLeft:3}}
                  buttonLabel={ page.name }
                  buttonType={ButtonTypeEnum.TEXT}
                  buttonSize={ButtonSizeEnum.SMALL}
                />
              </Link>
            ))}
          </Box>

            <Grid sx={{ flexGrow: 0 }} item md={5} className="d-f jc-r" container spacing={2}>


              <Grid item >
                <Link to={AppRoutes.pagePath} >
                  <AppButton 
                    buttonLabel={ t("Login") }
                    buttonType={ButtonTypeEnum.OUTLINE}
                    buttonSize={ButtonSizeEnum.SMALL}
                  />
                </Link>
              
              </Grid>
              <Grid item >
                <Link to={AppRoutes.pagePath} >
                  <AppButton 
                    buttonLabel={ t("Sign up") }
                    buttonType={ButtonTypeEnum.PRIMARY}
                    buttonSize={ButtonSizeEnum.SMALL}
                    buttonColor={ButtonColorEnum.WHITE_BG}
                    
                  />
                </Link>
              
              </Grid>
 
            </Grid>
          
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;