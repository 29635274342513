import React  from "react"
import Footer from "components/SelfCheckin/Footer/Footer"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import './MessageScreen.scss'

export default function MessageScreen({message, subMessage, mimg, forwordAddFunc, forwordStep, backAddFunc, backStep, imgToTop=true, forwordTitle, forceFooter, arrowLeft=false, arrowRight=false}){

  return (

    <div className="as-cover-bloc" >
      <div className="c-txt">
        {imgToTop && <>
          
          <img src={mimg} alt="" style={{maxWidth: '700px'}}/>
          <div className="v-space-4"></div>
          </>
        }
        <div className="font-1-heavy h3 c-txt uppercase white-color">{message}</div>
        <div className="v-space-2"> </div>
        <div className="font-1-heavy h5 c-txt uppercase white-color">{subMessage}</div>
        <div className="v-space-5"> </div>
        {!imgToTop && <img src={mimg} alt="" width="500" />}
        {arrowLeft && <PlayArrowIcon className="arrow left" /> }
        {arrowRight && <PlayArrowIcon className="arrow right" /> }
      </div>
      <Footer  
        forwordAddFunc={forwordAddFunc}
        forwordStep={forwordStep}
        backAddFunc={backAddFunc}
        backStep={backStep}
        forwordTitle={forwordTitle}
        forceFooter={forceFooter}
        />
    </div>
    
    )
}