import React, {useRef}  from "react"

import Grid from '@mui/material/Grid';

import {ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum } from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"
import AppleIcon from '@mui/icons-material/Apple';
import StoredCards from "./StoredCards/StoredCards"
import CreditCardForm from "./CreditCardForm/CreditCardForm"
import OrderSummary from "components/Bookings/OrderSummary/OrderSummary"

import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";
import useStore from 'store/store'

import './BookingPayment.scss'


export default function BookingPayment(){
  const {t} = useTranslation();

  const cardFormRef = useRef();

  const booking = useStore((state)=> state.booking);
  const storeActions = useStore((state)=> state.actions);

  function placeOrder(){
    if (cardFormRef && cardFormRef.current) {
      cardFormRef.current.handleSubmit();
    }else{
      storeActions.placeOrder({booking: booking, amount: booking.eprice})
    }
  } 

  return (
    <Grid  className="bk-payment-container" >


      <OrderSummary displayType={t('summery')} />
      <div className="v-space-10"></div>
      
      <StoredCards />
      <div className="v-space-1"></div>
      <CreditCardForm cardFormRef={cardFormRef}/>

      <div className="v-space-5"></div>

      <div className="d-f fd-c w-100 ">

        <AppButton 
          sx={{fontFamily: variables["font-1-heavy"]}}
          buttonLabel={ 
            <>
            <AppleIcon />
            {t("Pay")}
            </>
          } 
          buttonType={ButtonTypeEnum.PRIMARY}
          buttonSize={ButtonSizeEnum.MEDIUM}
          buttonColor={ButtonColorEnum.BLACK_BG_WHITE}
          onClickFunc={placeOrder}
        />

        <div className="v-space-2"></div>
        
        <AppButton 
          sx={{fontFamily: variables["font-1-heavy"]}}
          buttonLabel={ t("Place Order - $"+booking.eprice) }
          buttonType={ButtonTypeEnum.PRIMARY}
          buttonSize={ButtonSizeEnum.MEDIUM}
          buttonColor={ButtonColorEnum.PRIMARY1_BG}
          onClickFunc={placeOrder}
        />

      </div>

    </Grid>
    )
}