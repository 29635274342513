import React, {useMemo, useState}  from "react"

import Grid from '@mui/material/Grid';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useStore from 'store/store'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import creditCard from 'assets/images/creditCard.png';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import './CreditCardForm.scss'

const creditCardSchema = Yup.object().shape({
  card_number: Yup.string().matches(/^\d{16,16}$/, {message: "Please enter valid Credit Card."}).required('Required'),
  card_name: Yup.string().required('Required'),
  expiry_month: Yup.string().matches(/^\d{2,2}$/, {message: "Please enter expiry Month"}).required('Required'),
  expiry_year: Yup.string().matches(/^\d{4,4}$/, {message: "Please enter expiry Year"}).required('Required'),
  security_code: Yup.string().matches(/^\d{3,3}$/, {message: "Please enter valid security code"}).required('Required')
});


export default function CreditCardForm({cardFormRef}){
  const {t} = useTranslation();

  const initValues = useMemo(() => ({ 
    card_number: '',
    card_name: '',
    expiry_month: '',
    expiry_year: '',
    security_code: ''
  }), []);

  const booking = useStore((state)=> state.booking);
  const storeActions = useStore((state)=> state.actions);

  const [formOpen, setFormOpen] = useState(false);

  return (
    <Grid  className="" >

        <Accordion   onChange = {(e,expanded) => (expanded ? setFormOpen(true) : setFormOpen(false)) }  >
         <AccordionSummary
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
           <Grid item xs={1.5} className="d-f ai-c para3 font-1-light-bt">
             <img src={creditCard} alt="" /> 
           </Grid>
           <Grid item xs={8} className="d-f ai-c para3 font-1-light-bt">
             {t("Add card")}
           </Grid>
           <Grid item xs className="d-f jc-r ai-c">
             <PlayArrowIcon sx={{padding:1.3}} />
           </Grid>
         </AccordionSummary>
         <AccordionDetails>
          {
            formOpen &&
            <Formik
            innerRef={cardFormRef}
            initialValues={initValues}
            validationSchema={creditCardSchema}
            onSubmit={(values)=>{
              storeActions.placeOrder({booking: booking, amount: 5000.565})
              }
            }
          >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Field  id="card_number" className="field w-100  para2 font-1-book" name="card_number" placeholder={t('Card Number')} />
                  <div className="v-space-1"></div>
                  {errors.card_number && touched.card_number ? <div className="error-color">{errors.card_number}</div> : null}
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Field  id="card_name" className="field w-100  para2 font-1-book" name="card_name" placeholder={t('Name on Card')} />
                  <div className="v-space-1"></div>
                  {errors.card_name && touched.card_name ? <div className="error-color">{errors.card_name}</div> : null}
                </Grid>

                <Grid container spacing={2} item xs={12} lg={12}>
                  <Grid  item  xs={4} >
                    <Field  id="expiry_month" className="field w-100 para2 font-1-book" name="expiry_month" placeholder={t('Expiry Month')} />
                    <div className="v-space-1"></div>
                    {errors.expiry_month && touched.expiry_month ? <div className="error-color">{errors.expiry_month}</div> : null}
                  </Grid>

                  <Grid  item  xs={4} >
                    <Field  id="expiry_year" className="field w-100  para2 font-1-book" name="expiry_year" placeholder={t('Expiry Year')} />
                    <div className="v-space-1"></div>
                    {errors.expiry_year && touched.expiry_year ? <div className="error-color">{errors.expiry_year}</div> : null}
                  </Grid>

                  <Grid  item  xs={4} >
                    <Field  id="security_code" className="field w-100 para2 font-1-book" name="security_code" placeholder={t('CVC')} />
                    <div className="v-space-1"></div>
                    {errors.security_code && touched.security_code ? <div className="error-color">{errors.security_code}</div> : null}
                  </Grid>
                </Grid>

              </Grid>
            </Form>
            )}
          </Formik>
        }
        </AccordionDetails>
      </Accordion>

    </Grid>
    )
}