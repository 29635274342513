import React, {useEffect}  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import Flight from "components/Flight/Flight"
import Actions from "components/SelfCheckin/Actions/Actions"
import Footer from "components/SelfCheckin/Footer/Footer"

import { useTranslation } from 'react-i18next';
import {
  SelfCheckinStepEnum
} from "components/shared/enums/global-enums.tsx";

import useStore from 'store/store'
import './Passenger.scss'

export default function Passenger({typeFor}){
  const {t} = useTranslation();
  const [redirect, ] = useSelfCheckinNafigation();
                  
  const selfCheckinChoices = useStore((state)=> state.selfCheckinChoices);
  const scannedElms = useStore((state)=> state.scannedElms);
  let flightRecords = useStore((state)=> state.flightRecords);
  const storeActions = useStore((state)=> state.actions);

  let message;
  if (typeFor === SelfCheckinStepEnum.ITEMS_TO_PRINT){
    message = t("CHOOSE ITEMS TO PRINT");
  }

  useEffect(()=>{
    storeActions.setSelfCheckinMode(true);
    if (typeFor === SelfCheckinStepEnum.PASSENGER){
      let ln = scannedElms.last_name;
      let fn = scannedElms.first_name;


      const urlParams = {};
      urlParams.random_singapore_only = true;
      urlParams.first_name = fn;
      urlParams.last_name = ln;

      if (ln === "TAN" && (fn === "FEI JI VICTORIA" || fn === "JI CHANG OLIVIA")){
        urlParams.pnr = "CA1234";
        urlParams.use_pnr_only = true;

        urlParams.random_singapore_only = null;
      }

      storeActions.fetchFlightRecords(urlParams, storeActions.preparePanels);
    }
  }, [storeActions, typeFor, scannedElms]);

  useEffect(()=>{
    for (var i = 0; i<=flightRecords.length-1; i++) {
      storeActions.setSelfCheckinChoices(i, "fr_id", flightRecords[i].id);
      storeActions.setSelfCheckinChoices(i, "first_name", flightRecords[i].first_name);
      storeActions.setSelfCheckinChoices(i, "last_name", flightRecords[i].last_name);
      if (scannedElms.first_name === flightRecords[i].first_name && scannedElms.last_name === flightRecords[i].last_name){
        storeActions.setSelfCheckinChoices(i, "confirmed", true);
      }
    }
  }, [storeActions, flightRecords, scannedElms]);

  useEffect(()=>{
    if (!scannedElms.first_name){
      redirect(0, SelfCheckinStepEnum.IDLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className="passenger-cover-bloc">
      <div className="font-1-heavy h3 c-txt uppercase white-color">{message}</div>
      <div className="v-space-4"> </div>
      
      <div className="d-f fd-r">
      {
        flightRecords.map((fr, key)=> {
          if (typeFor === SelfCheckinStepEnum.ITEMS_TO_PRINT && !selfCheckinChoices[key].confirmed){
            return null;
          }
          return (
            <div key={key} className="ticket-container">
              <Flight flightRecord={fr}/>
              {selfCheckinChoices[key] && <Actions flightRecord={fr}  sCC={selfCheckinChoices[key]} index={key} gTypeFor={typeFor} />}
            </div>
          )
        }) 
      }
      </div>
      
      <div className="v-space-8"> </div>
      <Footer backStep={
        (typeFor === SelfCheckinStepEnum.PASSENGER)? -3:
        (typeFor === SelfCheckinStepEnum.ITEMS_TO_PRINT && storeActions.checkinChoicesBags().length === 0 )? -3 : -1
      }
      />
    </div>
    
    )
}