import React  from "react"
import {Link} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {AppRoutes} from "utils/routes";
import {ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum } from "components/shared/enums/global-enums.tsx";
import AppButton from "components/shared/AppButton/AppButton"
import { useTranslation } from 'react-i18next';

export default function Links({services, login, register, guidPnr, isGreating, setIsGreating}){
  const {t} = useTranslation();

  return (
    <Grid className="d-f jc-sb ai-c l-txt">

      { 
        guidPnr && 
        <Link to={AppRoutes.pagePath} >
          <AppButton 
            sx={{textDecoration: 'underline'}}
            buttonLabel={ t("Can’t find your 6-letter reference number?") }
            buttonType={ButtonTypeEnum.TEXT}
            buttonSize={ButtonSizeEnum.SMALL}
            buttonColor={ButtonColorEnum.PRIMARY1}
            actionType="submit"
          />
        </Link>
      }

      { 
        services && 
        <Link  to={(isGreating?  "": AppRoutes.pagePath)}>
        <AppButton 
          sx={{textDecoration: 'underline'}}
          buttonLabel={ (isGreating? t("Book our service or check the current rates."): t("Learn more about our services")) }
          buttonType={ButtonTypeEnum.TEXT}
          buttonSize={ButtonSizeEnum.SMALL}
          buttonColor={ButtonColorEnum.PRIMARY1}
          actionType="submit"
          onClickFunc={()=>{
            if (isGreating){
              setIsGreating(false);
            }
          }}
        />
        </Link>
      }

      { 
        login && 

        <>
          <Typography variant="span" className="para2 font-1-medium primary1-color">
            {t("Have an account?")}
          </Typography>
          <Link to={AppRoutes.pagePath} >
            <AppButton 
              sx={{textDecoration: 'underline'}}
              buttonLabel={ t("Log in") }
              buttonType={ButtonTypeEnum.TEXT}
              buttonSize={ButtonSizeEnum.SMALL}
              buttonColor={ButtonColorEnum.PRIMARY1}
              actionType="submit"
              />
          </Link>
        </>
      }


      {
        register && 
        <Link to={AppRoutes.pagePath} >
          <AppButton 
            sx={{textDecoration: 'underline'}}
            buttonLabel={ t("Register") }
            buttonType={ButtonTypeEnum.TEXT}
            buttonSize={ButtonSizeEnum.SMALL}
            buttonColor={ButtonColorEnum.PRIMARY1}
            actionType="submit"
          />
        </Link>
      }
    </Grid>
    )
}