import React,  {useEffect, useState}  from "react"

import Header from "components/shared/Header/Header"


import { useTranslation } from 'react-i18next';

import './ScaleTest.scss'

export default function ScaleTest(){
  const {t} = useTranslation();

  const [subMessage, setSubMessage] = useState(null);

  const socket = new WebSocket("ws://127.0.0.1:1880/weight");

  useEffect(()=>{

    async function readBagWeight () {
      // Connection opened
      console.log("start");
      socket.addEventListener("open", event => {
        console.log("open");
        socket.send("COX");
      });

      socket.addEventListener("error", event => {
        setSubMessage(t("Load cell not connected!"));
      });

      // Listen for messages
      socket.addEventListener("message", event => {
        let wt = event.data;
        wt = wt.substring(wt.indexOf(",0") + 2);
        wt = wt.substring(0, wt.length-4);
        wt = wt.replace(" ", "");
        wt = parseFloat(wt)/100;
        if (subMessage !== wt){
          setSubMessage(String(wt)+"kg");
        }else{
          setSubMessage("- -");
        }
      });
    }

    readBagWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div>
      <Header/>
      

      <div className="d-f jc-c ai-c h-100 fd-c "> 
        <div className="v-space-10"></div>
        <div className="v-space-10"></div>
        <span className="h3">Weigh your bag</span>
        <div className="v-space-3"></div>
        <span className="h4">{subMessage}</span>
        <div className="v-space-6"></div>

      </div>
      <div className="v-space-10"></div>
      <div className="v-space-10"></div>

    </div>
    )
}