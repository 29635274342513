import Api from 'utils/api'
import i18n from 'utils/i18n'
// import {produce} from 'immer'


export const flightRecordSlice = ((set, get) => ({
  flightRecords: [],

  actions: {
    checkLockedFlightRecords: () => {
      const lockedFR = get().flightRecords.find(fr => fr.locked);

      if (get().flightRecords?.length > 0 && lockedFR) return true; 

      return false;
    },
    setFlightRecords: async (newFlightRecords) => {
      set((state) => ({ flightRecords: newFlightRecords}));
    },

    fetchFlightRecords: async (params, callback) => {
      get().actions.startLoading();
      const api = new Api();
      api.getFlightRecords(params)
        .then((response) => {
          set((state) => ({ flightRecords: response.data.flight_records}));
          try {
            callback();
          } catch (exceptionVar) {
          }
          get().actions.stopLoading();
        })
      .catch((err) => {
        get().actions.stopLoading();
        set((state) => ({ flightRecords: null}));
        get().actions.setSnackMessage({show: true, type: "error", text: i18n.t(err.response.data[0]?.message)})
      });
    },

  }

}))