import React, {useState}  from "react"
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from "components/SelfCheckin/Footer/Footer"
import { useTranslation } from 'react-i18next';

import singapore from 'assets/images/airlines/bigsingapore.png'
import ana from 'assets/images/airlines/bigana.png'
import eva from 'assets/images/airlines/bigeva.png'

import './AirlineSelection.scss'

export default function AirlineSelection(){
  const {t} = useTranslation();

  const [choosenAirline, ] = useState('');
  const [redirect,] = useSelfCheckinNafigation();

  const airlines = [
    { img: singapore, name: 'singapore' }, 
    { img: ana, name: 'ana' }, 
    { img: eva, name: 'eva' }
  ];

  function handleChooseAirline(airline){
    if (airline !== airlines[0].name) return;
    redirect(1);
  } 

  return (

    <div className="as-cover-bloc">
      <div className="">
        <div className="font-1-heavy h3 c-txt uppercase white-color">{t("PLEASE SELECT YOUR AIRLINE")}</div>
        <div className="v-space-5"> </div>
        <Grid container spacing={2}>
          {airlines.map((airline, key) => (
            <Grid item xs={12} md={12} lg key={key} >
              <Box
                className={`img-btn  more-height  ${airline.name === choosenAirline ? 'active': ''}`}
                onClick={()=>handleChooseAirline(airline.name)}
                >
                <img src={airline.img} height="50" alt="" />
              </Box>
            </Grid>
          ))}
        </Grid>

      </div>

      <Footer />
    </div>
    
    )
}