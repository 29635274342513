import React , {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, BagsType, FlightStepType
} from "components/shared/enums/global-enums.tsx";

import BagType from "components/Services/ServiceForm/Bags/BagType/BagType"

import Links from "components/shared/Links/Links"
import AppButton from "components/shared/AppButton/AppButton"

import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";
import useStore from 'store/store'
import {AppRoutes} from "utils/routes";
import './CheckPrices.scss'

export default function CheckPrices(){

  const {t} = useTranslation();
  const storeActions = useStore((state)=> state.actions);
  const bookingDetail = useStore((state)=> state.bookingDetail);
  const flightRecord = {id: -1};
  flightRecord.booking_detail = bookingDetail;

  const typeFor = FlightStepType.COLLECTION;
  let [price, setPrice] = useState(0);

  useEffect(()=>{
    storeActions.resetBookingDetail();
  }, [storeActions])

  function getPrice(eprice){
    setPrice(eprice);
  }


  return (
    <Grid container className="cp-container">

      <Typography variant="span" className="h5 font-1-heavy primary1-color">
        {t("Low prices,")}
      </Typography>
      <div className="v-space-1"></div>
      <Typography variant="span" className="h5 font-1-heavy white-color">
        {t("high standards.")}
      </Typography>

      <div className="v-space-5"></div>

      <Typography variant="span" className="para1 font-1-heavy primary1-color">
        {t("How many bags would you like to check in?")}
      </Typography>

      <div className="v-space-3"></div>
      <Grid container className="bags-simulator-container">
        <Grid item xs={12} className="d-f jc-c">
          <BagType flightRecord={flightRecord} simulator={true} typeFor={typeFor} text={t("Bags")} subText={t("Standard bags < 32kg")} itemName={BagsType.BAGS}/>
        </Grid>
        <div className="v-space-3"></div>
        <Grid  item xs={12}  className="d-f jc-c">
          <BagType flightRecord={flightRecord} simulator={true} typeFor={typeFor} text={t("Child carriers")} subText={t("Prams, strollers, car seats, etc.")} itemName={BagsType.CHILD_CARRIERS}/>
        </Grid>
        <div className="v-space-3"></div>
        <Grid item xs={12}  className="d-f jc-c">
          <BagType flightRecord={flightRecord} simulator={true} typeFor={typeFor} text={t("Odd-Sized")} subText={t("Instruments, surfboards, bikes, etc.")} itemName={BagsType.ODD_SIZED_LAGGAGES}/>
        </Grid>
      </Grid>

      <div className="v-space-3"></div>
      <Grid  container className="h6 font-1-heavy primary1-color">
        <Grid item xs={6} >
          {t("Price from")}
        </Grid>
        <Grid item xs={6} className="r-txt">
          {t("SGD $")}{price === 0 ? "--" : price}
        </Grid>
      </Grid>
      <div className="v-space-6"></div>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AppButton 
            onClickFunc={()=>{
              storeActions.estimatePrice({bookingDetail: bookingDetail}, getPrice)
            }}
            sx={{width: '100%', fontSize: variables["para1-bis"]+'px', fontFamily: variables["font-1-heavy"]}}
            buttonLabel={ t("Get Price") }
            buttonType={ButtonTypeEnum.PRIMARY}
            buttonSize={ButtonSizeEnum.MEDIUM}
            buttonColor={ButtonColorEnum.PRIMARY3_BG}
          />
        </Grid>
        <Grid item xs={6}>
          <Link to={AppRoutes.indexPath+"?activeTab=BookService&personalInfo=1"}>
            <AppButton 
              sx={{width: '100%', fontSize: variables["para1-bis"]+'px', fontFamily: variables["font-1-heavy"]}}
              buttonLabel={ t("Book Now") }
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonSize={ButtonSizeEnum.MEDIUM}
              buttonColor={ButtonColorEnum.PRIMARY1_BG}
            />
          </Link>
        </Grid>

      </Grid>
      <div className="v-space-5"></div>

      <Links services={true} />

    </Grid>
    )
}