import {useSearchParams} from 'react-router-dom';
export default function useSelfCheckinNafigation() {
  let [searchParams, setSearchParams] = useSearchParams();
  let activeStep = Number(searchParams.get("activeStep"));
  const redirect = (move, step) => {
    setSearchParams(params => {
      if (step !== undefined) params.set("activeStep", step);
      else if (move) params.set("activeStep", activeStep+move);
      return params;
    });
  }

  return [redirect, activeStep];
  
};