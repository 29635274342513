import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Formik, Field, Form } from 'formik';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";

import Links from "components/shared/Links/Links"
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AppButton from "components/shared/AppButton/AppButton"
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import variables from "utils/_variables.module.scss";

import singapore from 'assets/images/airlines/singapore.png'
import ana from 'assets/images/airlines/ana.png'
import eva from 'assets/images/airlines/eva.png'
import {AppRoutes} from "utils/routes";

import useStore from "store/store";
import './ChooseAirline.scss'

const flightRecordSchema = Yup.object().shape({
  airline: Yup.string().required('Choose an Airline please'),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  pnr: Yup.string().min(6, 'Too Short!').max(6, 'Too Long!').required('Required')
});

export default function ChooseAirline(){
  const {t} = useTranslation();
  const navigate = useNavigate();
  let storeActions = useStore((state)=> state.actions);
  const [choosenAirline, setChoosenAirline] = useState('');
  const [pnr, setPnr] = useState('');
  const frFirstName = useStore((state)=> state.frFirstName);
  const frLastName = useStore((state)=> state.frLastName);

  const [bookingCode, setBookingCode] = useState('');
  const [showCodeError, setShowCodeError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initValues = { 
    airline: choosenAirline,
    first_name: frFirstName,
    last_name: frLastName,
    pnr: pnr
  }

  const airlines = [
    { img: singapore, name: 'singapore' }, 
    { img: ana, name: 'ana' }, 
    { img: eva, name: 'eva' }
  ];

  useEffect(()=>{
    if (!frFirstName || !frLastName){
      navigate("/");
    }
  }, [frFirstName, frLastName, navigate]);


  function handleRedirect(){
    if (storeActions.checkLockedFlightRecords() && !bookingCode) {
      setShowCodeError(true);
      return;
    }
    let values = {airline: choosenAirline, first_name: frFirstName, last_name: frLastName, pnr: pnr, booking_code: bookingCode};
    let prmStr = new URLSearchParams(values).toString();
    navigate(AppRoutes.flightsPath+"?"+prmStr);
  }


  return (
    <Box className="ca-container">

      <Typography variant="span" className="h5 font-1-heavy primary1-color">
        {t("Choose your airline: ")}
      </Typography>

      <div className="v-space-7"></div>

      <Grid container spacing={2}>
        {airlines.map((airline, key) => (
          <Grid item xs={12} md={12} lg key={key} >
            <Box
              className={`img-btn ${airline.name === choosenAirline ? 'active': ''}`}
              onClick={()=>setChoosenAirline(airline.name)}
              >
              <img src={airline.img} alt="" />
            </Box>
          </Grid>
        ))}
      </Grid>

      <div className="v-space-7"></div>

      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={flightRecordSchema}
        onSubmit={(values)=>{
            storeActions.fetchFlightRecords(values, ()=>{
              let lockedFR = storeActions.checkLockedFlightRecords()
              if ( lockedFR) handleOpen();
              else handleRedirect();
            });
          }
        }
      >
      {({ errors, touched }) => (
        <Form>
          <Grid container spacing={2}>
              {!choosenAirline ? <div className="error-color">{errors.airline}</div> : null}
            <Grid item xs={12} lg={9}>
              <Field id="airline" type="hidden"  name="airline"  value={choosenAirline? choosenAirline :''}/>

              <Field  id="pnr" className="field w-100 " name="pnr" placeholder={t('Enter your airline booking reference')} onChange={(e)=>setPnr(e.target.value)}/>
              <div className="v-space-1"></div>
              {errors.pnr && touched.pnr ? <div className="error-color">{errors.pnr}</div> : null}
              <Field id="first_name" type="hidden"  name="first_name" value={frFirstName} />
              <Field id="last_name" type="hidden"  name="last_name" value={frLastName} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <AppButton 
                  sx={{width: '100%', fontSize: variables["para1"], fontFamily: variables["font-1-heavy"]}}
                  buttonLabel={ t("Go") }
                  buttonType={ButtonTypeEnum.PRIMARY}
                  buttonSize={ButtonSizeEnum.MEDIUM}
                  buttonColor={ButtonColorEnum.PRIMARY_BG}
                  actionType="submit"
                />
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>

      <div className="v-space-7"></div>

      <Links guidPnr={true} />

      <Modal open={open}  onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="lock-popup">
          <div className="d-f para2 primary3-color font-1-heavy ai-c">
            {t("Booking Code is required for this flight")} <ReportProblemIcon sx={{fontSize: 16}} />
          </div>
          <div className="v-space-2" ></div>
          <div className="d-f para2 black-color font-1-book ai-c">
            {t("Services have already been booked for this trip. Please provide the booking code to access the flight information")}
          </div>
          <div className="v-space-2" ></div>
          <div >
            <input  className="field w-100 para2 font-1-book" onChange={e => setBookingCode(e.target.value)} placeholder={t('Booking Code')} />
            <div className="v-space-1"></div>
            {showCodeError && <div className="error-color">{t("Required")}</div>}
            <div className="v-space-1"></div>
          </div>
          <div className="v-space-2" ></div>
          <div className="d-f jc-r">
            <AppButton 
              buttonLabel={ t("Access Flight Now") }
              buttonType={ButtonTypeEnum.PRIMARY}
              buttonSize={ButtonSizeEnum.SMALL}
              onClickFunc={handleRedirect}
            />
          </div>
        </Box>
      </Modal>
   
    </Box>
    )
}