import { create } from 'zustand'
import { flightRecordSlice } from 'store/slices/flightRecordSlice'
import { bookingDetailSlice } from 'store/slices/bookingDetailSlice'
import { bookingSlice } from 'store/slices/bookingSlice'
import { paymentSlice } from 'store/slices/paymentSlice'
import { userSlice } from 'store/slices/userSlice'
import { configsSlice } from 'store/slices/configsSlice'
import { cachingSlice } from 'store/slices/cachingSlice'
import { utilsSlice } from 'store/slices/utilsSlice'
import { selfCheckinSlice } from 'store/slices/selfCheckinSlice'

const useStore = create((...a) => ({
  ...paymentSlice(...a),
  ...flightRecordSlice(...a),
  ...bookingDetailSlice(...a),
  ...userSlice(...a),
  ...bookingSlice(...a),
  ...configsSlice(...a),
  ...cachingSlice(...a),
  ...utilsSlice(...a),
  ...selfCheckinSlice(...a),
  actions:{
    ...paymentSlice(...a).actions,
    ...flightRecordSlice(...a).actions,
    ...bookingDetailSlice(...a).actions,
    ...userSlice(...a).actions,
    ...bookingSlice(...a).actions,
    ...configsSlice(...a).actions,
    ...cachingSlice(...a).actions,
    ...utilsSlice(...a).actions,
    ...selfCheckinSlice(...a).actions
  }
}));

export default useStore; 