import React from "react"
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import useSelfCheckinNafigation from 'utils/useSelfCheckinNafigation';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum, SelfCheckinStepEnum
} from "components/shared/enums/global-enums.tsx";

import useStore from 'store/store'
import variables from "utils/_variables.module.scss";
import AppButton from "components/shared/AppButton/AppButton"

export default function Initial({sCC, index}){
  const {t} = useTranslation();
  const [redirect,] = useSelfCheckinNafigation();
  const storeActions = useStore((state)=> state.actions);

  function handleSelect(){
    if (sCC.confirmed){
      storeActions.setSelfCheckinChoices(index, "typeFor", SelfCheckinStepEnum.SELECT_BAGS)
      storeActions.setSelfCheckinChoices(index, "confirmed", true)
    }else{
      storeActions.setPendingPassScan({first_name: sCC.first_name,
        last_name: sCC.last_name});
      redirect(-1, SelfCheckinStepEnum.SCAN_PASSPORT);
    }
  }

  return (
    <Grid item xs sx={{paddingTop: 1.5, paddingBottom: 1.5}} className="d-f jc-c bg-secondary1-color  left-slot right-slot">
        <AppButton 
          sx={{fontSize: 20, fontFamily: variables["font-1-heavy"]}}
          buttonLabel={t("SELECT PASSENGER")}
          buttonType={ButtonTypeEnum.TEXT}
          buttonSize={ButtonSizeEnum.LARGE}
          buttonColor={ButtonColorEnum.PRIMARY1}
          onClickFunc={()=>handleSelect()}
        />
    </Grid>
    )
}