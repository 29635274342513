import React from "react"

import Grid from '@mui/material/Grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";
import {dateOnly, timeOnly, exactAirport} from 'utils/helperFunctions'
import { useTranslation } from 'react-i18next';

import AppButton from "components/shared/AppButton/AppButton"
import useStore from 'store/store'

export default function Edit({flightRecord, bookingDetail, typeFor}){

  const {t} = useTranslation();

  
  const storeActions = useStore((state)=> state.actions);
  const activeStepPanel = useStore((state)=> state.activeStepPanel);

  let date = new Date(bookingDetail[typeFor+"_at"])
  let date2 = new Date(date);
  date2.setMinutes(date2.getMinutes()+30);

  return (
      <Grid container spacing={1} className="d-f jc-c" >
        { !(activeStepPanel[flightRecord.id]!=='' && bookingDetail["completed_"+typeFor]) &&
          <Grid item xs={6} className="d-f fd-c jc-sb">
            <div>
              <div className="para1 font-1-medium accent-color cap">{t(typeFor)}</div>
              <div className="para2 font-1-book cmn-color">{bookingDetail[typeFor+"_address"]}</div>
            </div>
            
              <div>
                { bookingDetail[typeFor+"_room_number"] && 
                  <>
                  <div className="v-space-1"></div>
                  <div className="para3 font-1-light-bt cmn-color">{t("room/unit ")}{bookingDetail[typeFor+"_room_number"]}</div>
                  </>
                }
                <div className="v-space-3"></div>
              </div>
          </Grid>
        }
        <Grid item xs={6} className="d-f fd-c jc-sb">
          { (!(activeStepPanel[flightRecord.id]!=='' && bookingDetail["completed_"+typeFor]) &&
            <div>
              <div className="para2 font-1-book cmn-color d-f jc-r r-txt">{dateOnly(date)}</div>
              <div className="para2 font-1-medium cmn-color d-f jc-r r-txt ">{timeOnly(date)+' - '+timeOnly(date2)}</div>
            </div>) 
            || 
              <div className="para3 cmn-color c-txt w-100 font-1-light-bt">{exactAirport(typeFor, flightRecord).city}</div>
          }
          <div className="v-space-1"></div>
          <AppButton 
            sx={{justifyContent:'right', padding: '0'}}
            buttonLabel={ t("Edit") }
            buttonType={ButtonTypeEnum.TEXT}
            buttonSize={ButtonSizeEnum.MEDIUM}
            buttonColor={ButtonColorEnum.WHITE}
            buttonEndIcon={<ArrowDropDownIcon/>}
            onClickFunc={()=>{
              storeActions.togglePanel("bagsPanel", flightRecord.id, true);
              storeActions.toggleFlightStepPanel(flightRecord.id, typeFor)
              }
            }/>
        </Grid>
        
      </Grid>
    )
}