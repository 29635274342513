import React from "react"
import { useTranslation } from 'react-i18next';
import {
  AudienceTypes, ButtonTypeEnum, ButtonSizeEnum, ButtonColorEnum
} from "components/shared/enums/global-enums.tsx";

import AppButton from "components/shared/AppButton/AppButton"

import './Links.scss'



export default function Links({requestCode, setRequestCode, linksFor, setStep}){
  
  const {t} = useTranslation();

  return (
    <>
      {
        
        <>
          <AppButton 
            onClickFunc={()=>(
              linksFor === AudienceTypes.PARTNER ? setRequestCode(!requestCode) : null) }
            sx={{textDecoration: 'underline', marginLeft: 0, paddingLeft:0}}
            buttonLabel={ t(linksFor === AudienceTypes.PARTNER ? (requestCode ? "Already have a code?" :"Request Code"): "Cookies Settings") }
            buttonType={ButtonTypeEnum.TEXT}
            buttonSize={ButtonSizeEnum.SMALL}
            buttonColor={ButtonColorEnum.WHITE}
            actionType="submit"
          />

          <div className="h-space-3"></div>
          
          <AppButton 
            onClickFunc={()=>(
              linksFor === AudienceTypes.PARTNER ? setStep(2) : null) }
          
            sx={{textDecoration: 'underline'}}
            buttonLabel={ t(linksFor === AudienceTypes.PARTNER ? "Get in touch" :  "Privacy Policy") }
            buttonType={ButtonTypeEnum.TEXT}
            buttonSize={ButtonSizeEnum.SMALL}
            buttonColor={ButtonColorEnum.BLACK}
            actionType="submit"
          />
        </>
      }
      
    </>
    )
}