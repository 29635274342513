import axios from "axios";

export default class Api {
  constructor() {
    this.access_token = null;
    this.client = null;
    this.apiUrl = process.env.REACT_APP_API_ENDPOINT;
  }
  init = () => {
    this.access_token = ""
    let headers = {
      Accept: "application/json",
    };
    if (this.access_token) {
      headers.Authorization = `Bearer ${
        this.access_token
      }`;
    }
    this.client = axios.create({
      baseURL: this.apiUrl,
      timeout: 80000,
      headers: headers,
    });
    return this.client;
  };

  reloadUser = (data) => {
    return this.init().get('/users/reload');
  };

  getFlightRecords = (data) => {
    const prmStr = new URLSearchParams(data).toString();
    return this.init().get("/flight_records?"+prmStr);
  };

  getBookingDetail = (data) => {
    const prmStr = new URLSearchParams(data).toString();
    return this.init().get("/booking_details?"+prmStr);
  };

  saveBookingDetail = (data) => {
    return this.init().post("/booking_details/", data);
  };

  estimatePrice = (data) => {
    return this.init().post("/booking_details/estimate_price", data);
  };

  getBooking = (data) => {
    return this.init().get("/bookings/"+data.bookingCode);
  };

  createUser = (data) => {
    return this.init().post("/users",data);
  };

  getUserByCode = (data) => {
    return this.init().get("/users/"+data.access_code);
  };

  updateBooking = (data) => {
    return this.init().put("/bookings/"+data.booking.code+"/contact", data);
  };

  placeOrder = (data) => {
    return this.init().post("/payments", data);
  };

  getConfigs = (data) => {
    const prmStr = new URLSearchParams(data).toString();
    return this.init().get("/configs?"+prmStr);
  };

  /*Self Checkins*/

  scanPassport = (data) => {
    return this.init().post("/self_checkins/scan_passport", data);
  };

  stopScanPassport = (data) => {
    return this.init().post("/self_checkins/stop_scan_passport", data);
  };

  printElements = (data) => {
    return this.init().post("/self_checkins/print_elements", data);
  };

  // readScanInfo = (data) => {
  //   return this.init().get("/self_checkins/read_scan_info/"+data.location_id);
  // };



}
