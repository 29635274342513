import React from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FlightStepType } from "components/shared/enums/global-enums.tsx";
import Edit from "./Edit/Edit"
import Initial from "./Initial/Initial"
import Save from "./Save/Save"
import Start from "./Start/Start"
import {exactAirport} from 'utils/helperFunctions'
import useStore from 'store/store'
import './Actions.scss'

export default function Actions({flightRecord}){
  

  const defaultBookingDetailValues = useStore((state)=> state.defaultBookingDetailValues);
  if (!flightRecord.booking_detail){
    flightRecord.booking_detail = defaultBookingDetailValues;
  }

  const bookingDetail = flightRecord.booking_detail;

  const activeStepPanel = useStore((state)=> state.activeStepPanel);
  const initialBtn = useStore((state)=> state.initialBtn);

  const actionsNames = [FlightStepType.COLLECTION, FlightStepType.DELIVERY]; 

  const rowActionShow = (bookingDetail?.completed_one_step && activeStepPanel[flightRecord.id]==='');

  // const initialAction = (!bookingDetail?.completed_one_step || initialBtn[flightRecord.id]);

  return (
    <>
      <Box className="actions-container">
        <Grid  container className={`d-f ${ rowActionShow?  "fd-c": 'fd-r'}`}>
          {
            initialBtn[flightRecord.id] && !bookingDetail?.completed_one_step && <Initial flightRecord={flightRecord}/>
          }
          {
            (!initialBtn[flightRecord.id] || bookingDetail?.completed_one_step) && actionsNames.map((actionsName, key)=>{
              let airport = exactAirport(actionsName, flightRecord);

              return(
                  <Grid key={key} item xs className={`act-container col-modifier ${actionsName}

                    ${(airport[actionsName+"_status"] === 1)? "" : 'inactive'}

                       ${rowActionShow && key===1? 'left-slot  right-slot':''}  ${!rowActionShow? (key===0? 'left-slot':'right-slot'): '' }  `}>

                    {
                      activeStepPanel[flightRecord.id]!==actionsName && 
                       ( (( !bookingDetail || !bookingDetail.id || !bookingDetail["completed_"+actionsName]) &&
                          <Start flightRecord={flightRecord} typeFor={actionsName}/> )
                        ||
                        ( <Edit flightRecord={flightRecord} bookingDetail={bookingDetail} typeFor={actionsName}/>)
                        )
                    }

                    {activeStepPanel[flightRecord.id]===actionsName && <Save  flightRecord={flightRecord} bookingDetail={bookingDetail} typeFor={actionsName}/> }

                  </Grid>
                )
            })
          }
        </Grid>
      </Box>
    </>
    )
}
