import React from "react"

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';
import singapore from 'assets/images/airlines/singapore.png'
import bigsingapore from 'assets/images/airlines/bigsingapore.png'
import eva from 'assets/images/airlines/eva.png'
import bigeva from 'assets/images/airlines/bigeva.png'
import ana from 'assets/images/airlines/ana.png'
import bigana from 'assets/images/airlines/bigana.png'
import {msToTime} from 'utils/helperFunctions'
import useStore from 'store/store'
import './FlightHeader.scss'

export default function FlightHeader({flightRecord, prevArrivalDate}){

  const {t} = useTranslation();
  const selfCheckinMode = useStore((state)=> state.selfCheckinMode);
  let airlines = {eva: eva, singapore: singapore, ana: ana};
  if (selfCheckinMode) airlines = {eva: bigeva, singapore: bigsingapore, ana: bigana};
  let diffTime = Math.abs(new Date(flightRecord.departure_at) - new Date(prevArrivalDate));
  diffTime = msToTime(diffTime);
  return (
    <>
      {flightRecord.layover && <Box className="fh-layover">{t("Layover: "+diffTime)}</Box>}

      <Box  className={`fh-container ${!flightRecord.layover? 'top-radius': '' }  ${selfCheckinMode ? "less-padds" :  ""}`}>
        <Grid container>
          <Grid item xs={6}>
            <img src={airlines[flightRecord.airline.toLowerCase()]} alt="" height={selfCheckinMode? 42 : 29} />
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={12} className="r-txt black-color-opac para4 font-1-light-bt">
              {t("Flight")}
            </Grid>
            <Grid item xs={12} className={`r-txt primary1-color font-1-heavy  ${selfCheckinMode? "h6" : "para3"} `}>
              {flightRecord.flight_number}
            </Grid>
          </Grid>
        </Grid>
    
      </Box>

    </>
    )
}